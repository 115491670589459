/* eslint-disable import/no-unresolved */
/* eslint-disable consistent-return */
import { Button, CloseButton, Text } from '@chakra-ui/react';
import { isCheckOTP } from '@react-ib-mf/style-guide-ui';
import { Modal, ModalBody, ModalChakra, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@wow/modal';
import { useEffect, useState } from 'react';

import { IModalValidation } from '../../../interfaces/IModalValidation';
import plainText from '../../../lang/es.json';

function ModalValidation({
  mail,
  isOpen,
  handleCloseModalValidation,
  handleCancelModalValidation,
  handleResendMail,
  isLoading,
}: IModalValidation) {
  const [timer, setTimer] = useState(300); //* tiempo 5 minutos // setear todo de nuevo en 300 el setTimer para que vuelva a ser 5 minutos
  const [canResend, setCanResend] = useState(false);

  useEffect(() => {
    if (timer > 0 && isOpen) {
      const interval = setInterval(() => {
        setTimer(prev => prev - 1);
      }, 1000);
      return () => clearInterval(interval);
    }
    if (!isOpen) {
      setCanResend(false);
      return setTimer(300);
    }
    setCanResend(true);
  }, [timer, isOpen]);

  const handleResend = () => {
    setTimer(300);
    setCanResend(false);
  };

  const formatTime = (time: number) => {
    const minutes = String(Math.floor(time / 60)).padStart(2, '0');
    const seconds = String(time % 60).padStart(2, '0');
    return `${minutes}:${seconds}`;
  };

  return (
    <Modal
      data-testid='ModalValidation'
      size='xl'
      isCentered
      closeOnEsc
      isOpen={isOpen}
      onClose={handleCloseModalValidation}
      variant='states'
    >
      <ModalOverlay />
      <ModalContent textAlign='center' py={5}>
        <CloseButton onClick={handleCloseModalValidation} sx={ModalChakra.close} />
        <ModalHeader state='success'>
          {isCheckOTP() ? (
            <Text fontSize='28px' fontWeight='700'>
              {plainText.modal.validation.title}
            </Text>
          ) : (
            <Text fontSize='28px' fontWeight='700'>
              {plainText.modal.confirmation.title}
            </Text>
          )}
          {isCheckOTP() ? (
            <Text fontFamily='Inter' mt={2} fontSize='14px' fontWeight='400'>
              {mail}
            </Text>
          ) : (
            <Text fontFamily='Inter' mt={2} fontSize='14px' fontWeight='400'>
              {mail}
              <br />
              {plainText.modal.confirmation.confirmMessage}
            </Text>
          )}
        </ModalHeader>
        <ModalBody>
          <Text mt={2} color='#037193' lineHeight='20px'>
            {plainText.modal.validation.descriptionTop} <br /> {plainText.modal.validation.descriptionBottom}
            <strong> {formatTime(timer)}</strong>
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button
            variant='primary-outline'
            onClick={() => {
              handleCancelModalValidation();
            }}
          >
            {plainText.modal.validation.cancelButton}
          </Button>
          <Button
            isLoading={isLoading}
            loadingText={plainText.modal.confirmation.isLoadingResendButton}
            data-testid='ModalValidationResend'
            variant='primary'
            onClick={() => {
              handleResendMail();
              handleResend();
            }}
            isDisabled={!canResend}
          >
            {plainText.modal.validation.resendButton}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default ModalValidation;
