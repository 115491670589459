/* eslint-disable import/no-unresolved */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable consistent-return */
import { Button, CloseButton, Text } from '@chakra-ui/react';
import { isCheckOTP } from '@react-ib-mf/style-guide-ui';
import { Modal, ModalBody, ModalChakra, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@wow/modal';
import { useEffect, useState } from 'react';

import plainText from '../../../lang/es.json';

interface IModalComponent {
  isOpen: boolean;
  onClose: () => void;
  updateEmail: () => void;
  resendEmail: () => void;
  email: string;
  isOTP?: boolean;
}

function ModalComponent({ isOpen, onClose, updateEmail, resendEmail, email }: IModalComponent) {
  const [timer, setTimer] = useState(300);
  const [canResend, setCanResend] = useState(false);

  const handleResend = () => {
    setTimer(300);
    setCanResend(false);
    resendEmail();
  };

  const formatTime = (time: number) => {
    const minutes = String(Math.floor(time / 60)).padStart(2, '0');
    const seconds = String(time % 60).padStart(2, '0');
    return `${minutes}:${seconds}`;
  };

  const handleUpdateEmail = () => {
    updateEmail();
  };

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;

    if (isOpen) {
      // Si el modal está abierto y el timer es mayor a 0, comienza el conteo
      if (timer > 0) {
        interval = setInterval(() => {
          setTimer(prev => prev - 1);
        }, 1000);
      } else {
        // Si el timer llega a 0, habilita el botón de reenviar
        setCanResend(true);
      }
    }

    return () => {
      if (interval) clearInterval(interval); // Limpia el intervalo cuando se desmonte o cambien las dependencias
      if (!isOpen) {
        // Resetea el temporizador y el estado cuando el modal se cierra
        setTimer(300);
        setCanResend(false);
      }
    };
  }, [timer, isOpen]);

  return (
    <Modal
      data-testid={isCheckOTP() ? 'ModalValidation' : 'ModalConfirmation'}
      size='xl'
      isCentered
      closeOnEsc
      isOpen={isOpen}
      onClose={onClose}
      variant='states'
    >
      <ModalOverlay data-testid={isCheckOTP() ? 'ModalValidationOverlay' : 'ModalConfirmationOverlay'} />
      <ModalContent data-testid={isCheckOTP() ? 'ModalValidationContent' : 'ModalConfirmationContent'} textAlign='center' py={5}>
        <CloseButton onClick={onClose} sx={ModalChakra.close} />
        <ModalHeader state='success'>
          <Text fontSize='28px' fontWeight='700'>
            {isCheckOTP() ? plainText.modal.validation.title : plainText.modal.confirmation.title}
          </Text>
          <Text fontFamily='Inter' mt={2} fontSize='14px' fontWeight='400'>
            {email}
            <br />
            {!isCheckOTP() && plainText.modal.confirmation.confirmMessage}
          </Text>
        </ModalHeader>
        <ModalBody>
          <Text mt={2} color='#037193' lineHeight='20px'>
            {isCheckOTP() ? plainText.modal.validation.descriptionTop : plainText.modal.confirmation.descriptionTop} <br />
            {isCheckOTP ? plainText.modal.validation.descriptionBottom : plainText.modal.confirmation.descriptionBottom}
            <br />
            <strong>{formatTime(timer)}</strong>
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button
            variant='primary-outline'
            data-testid={isCheckOTP() ? 'ModalValidationCancel' : 'ModalConfirmationCancel'}
            onClick={() => handleUpdateEmail()}
          >
            {isCheckOTP ? plainText.modal.validation.cancelButton : plainText.modal.confirmation.cancelButton}
          </Button>
          <Button
            variant='primary'
            onClick={handleResend}
            isDisabled={!canResend}
            data-testid={isCheckOTP ? 'ModalValidationResend' : 'ModalConfirmationResend'}
          >
            {isCheckOTP ? plainText.modal.validation.resendButton : plainText.modal.confirmation.resendButton}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default ModalComponent;
