import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import AppContainer from './layouts/AppContainer';
import AppContextProvider from './providers/AppContextProvider';
import Perfil from './views/Perfil';

function App() {
  return (
    <AppContextProvider>
      <Router>
        <Routes>
          <Route path='/' element={<AppContainer />} />
          <Route path='/perfil' element={<Perfil />} />
        </Routes>
      </Router>
    </AppContextProvider>
  );
}

export default App;
