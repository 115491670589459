// eslint-disable-next-line import/no-unresolved
import { isCheckOTP } from '@react-ib-mf/style-guide-ui';

import { ERROR_MESSAGE_ADD_PERSON_EMAIL } from './enums/errorMenssagesAddEmailPerson';

/* eslint-disable consistent-return */
interface IHandleErrorsAddEmailPerson {
  errorCode: string;
  errorKey: string;
  errorMessage: string;
  emailPerson: string;
}

const handleErrorsAddEmailPerson = ({ errorCode, errorKey, errorMessage, emailPerson }: IHandleErrorsAddEmailPerson) => {
  // El email ya está siendo utilizado por otra persona, funciona para otp y sin
  if (errorKey === '4033' && errorCode === '409') {
    return {
      title: 'No podemos actualizar tu email',
      description: `Parece que alguien más está usando el correo electrónico <br/>  ${emailPerson}. Por favor, elegí otro email.`,
      typeModal: 'error',
    };
  }

  // falta error de intentos no disponibles, funciona solo para otp
  if (errorKey === '3023' && errorCode === '409') {
    return {
      title: 'No podemos actualizar tus datos',
      description:
        'Cada vez que editás tus datos de contacto, se envía un email. Podés <br /> reenviarlo hasta 3 veces por día. Ya superaste el límite de hoy, volvé a <br />intentar mañana.',
      typeModal: 'error',
    };
  }

  //  409 no contemplado en los casos de arriba o un 400 o 404 o 500, funciona para otp y sin
  if (errorCode === '500' || errorCode === '409' || errorCode === '404' || errorCode === '400') {
    if (isCheckOTP()) {
      return {
        title: 'No podemos procesar el Token',
        description: 'El servicio no está disponible en este momento. <br/> Volvé a intentar más tarde.',
        typeModal: 'error',
      };
    }
    return {
      title: 'No podemos procesar tu solicitud',
      description: 'El servicio no está disponible en este momento. <br/> Volvé a intentar más tarde.',
      typeModal: 'error',
    };
  }

  // bloqueo de cuenta por intentos, funciona solo para otp
  if (errorCode === '401' && errorMessage === ERROR_MESSAGE_ADD_PERSON_EMAIL.OTP_INVALIDO) {
    return {
      title: 'El Token ingresado es incorrecto',
      description: '',
      typeModal: 'error',
    };
  }

  if (errorCode === '401' && errorMessage === ERROR_MESSAGE_ADD_PERSON_EMAIL.OTP_INVALIDO_ULTIMO_INTENTO) {
    return {
      title: 'Estás por bloquear tu usuario',
      description: 'Si volvés a ingresar mal el token, se bloqueará. <br/> Revisá la clave dinámica antes de volver a ingresarla.',
      typeModal: 'warning',
    };
  }

  if (
    errorCode === '401' &&
    (errorMessage === ERROR_MESSAGE_ADD_PERSON_EMAIL.PERSONA_BLOQUEADA_INTENTOS ||
      errorMessage === ERROR_MESSAGE_ADD_PERSON_EMAIL.PERSONA_BLOQUEADA)
  ) {
    return {
      title: 'Bloqueamos tu usuario',
      description:
        'Ingresaste mal el Token y por seguridad vamos a cerrar tu sesión. <br/> Podes desbloquear tu usuario en <strong>Gestión de claves</strong>.',
      typeModal: 'error',
      isLogout: true,
    };
  }
};

export default handleErrorsAddEmailPerson;
