import styled from 'styled-components';

const Wrapper = styled.div`
  padding-left: 22px;
  padding-right: 30px;
  height: 100vh;

  nav ol {
    display: flex;
    margin-bottom: 0.75rem;
    height: 2.125rem;
    font-size: 0.8125rem;
    line-height: 1rem;
    letter-spacing: 0.03125rem;
    gap: 0.25rem;
  }

  nav ol li:first-child {
    font-weight: 600;
    margin-left: 0.25rem;
  }

  .title {
    font-size: 40px !important;
    margin-bottom: 35px;
  }

  .chakra-alert .chakra-stack button:nth-of-type(2),
  .css-zliwli {
    margin-top: 5px;
  }

  .loading-resend-email-not-otp {
    width: 405px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    position: absolute;
    padding-bottom: 10px;
  }
`;

const SectionContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  .section__simpleSection {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .section__companySection {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
`;

export { SectionContainer, Wrapper };
