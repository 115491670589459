/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Spinner, Text, Tooltip } from '@chakra-ui/react';
// eslint-disable-next-line import/no-unresolved
import { Badge } from '@wow/badge';
import { useContext, useEffect, useRef, useState } from 'react';

import iconMail from '../../../assets/icons/mail.svg';
import iconPencil from '../../../assets/icons/pencil.svg';
import iconPhone from '../../../assets/icons/phone.svg';
import { ICompanySection } from '../../../interfaces/IProfile';
import SectionText from '../../../lang/es.json';
import { AppContext } from '../../../providers/AppContextProvider';
import getFormattedRole from '../../../utils/roles';
import isValidData from '../../../utils/validateData';
import DrawerOpComponent from '../../DrawerOpComponent/DrawerOpComponent';
import CompanyErrorState from '../../ErrorState/CompanyErrorState';
import { Body, BodyEmpty, Container, Footer, Header } from './styled';

function CompanySection({ title, id, emailBadges }: ICompanySection) {
  // cuando se importe DrawerComponentOperator -> pide como param -> data = drawerData
  // isOpern - isClone es un useState local de este archivo para que con el click en btn edit levante.
  const [drawerData, setDrawerData] = useState({ phoneNumber: '', email: '', countryCode: '' });
  const [isOpen, setIsOpen] = useState(false);
  const emailRef = useRef(null);
  const { profileData, profileStatus, fetchDataAndUpdateProfile, isLoading, setIsLoading } = useContext(AppContext) as any;

  const hasTooltip = ref => {
    return ref?.current?.offsetWidth < ref?.current?.scrollWidth;
  };

  useEffect(() => {
    fetchDataAndUpdateProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setDrawerData({
      phoneNumber: profileData?.phoneNumber ?? '',
      email: profileData?.operatorEmail,
      countryCode: profileData?.countryCode?.toString(),
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileData]);

  // eslint-disable-next-line consistent-return

  const capitalize = text => {
    if (!text || typeof text !== 'string') {
      return '';
    }
    const firstLetter = text.charAt(0).toString();
    const rest = text.slice(1);
    return firstLetter.toUpperCase() + rest;
  };

  const onClose = () => {
    setIsOpen(false);
  };

  const truncateText = (text, maxLength) => {
    return text.length >= maxLength ? `${text.slice(0, maxLength)}...` : text;
  };

  const isDisabledTooltipCompany = (text, maxLength) => {
    if (text.length >= maxLength) {
      return true;
    }
    return false;
  };

  const handleUpdateProfile = () => {
    setIsLoading(true);
    fetchDataAndUpdateProfile();
  };

  return (
    <Container id={id}>
      {isLoading && (
        <BodyEmpty>
          <div className='section__spinner'>
            <Spinner thickness='4px' speed='0.45s' emptyColor='gray.200' color='rgb(74, 143, 251)' size='xl' />
          </div>
        </BodyEmpty>
      )}
      {!isLoading && !profileData && (profileStatus === 500 || profileStatus === 0) && (
        <BodyEmpty>
          <CompanyErrorState hasButton buttonAction={handleUpdateProfile} />
        </BodyEmpty>
      )}
      {!isLoading && !profileData && (profileStatus === 400 || profileStatus === 401 || profileStatus === 409 || profileStatus === 404) && (
        <BodyEmpty>
          <CompanyErrorState />
        </BodyEmpty>
      )}
      {!isLoading && profileData && (
        <>
          <Header>
            <div className='section__title'>
              <h1>
                <Tooltip
                  hasArrow
                  label={capitalize(title)}
                  isDisabled={!isDisabledTooltipCompany(capitalize(title), 20)}
                  backgroundColor='#2F3034'
                  padding='8px'
                  borderRadius='8px'
                  fontFamily='Inter'
                  fontSize='12px'
                  fontWeight={400}
                  textAlign='center'
                >
                  <Text
                    textStyle='titleLg'
                    style={{
                      fontWeight: 'bold',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      maxWidth: '200px',
                    }}
                  >
                    {truncateText(capitalize(title), 20)}
                  </Text>
                </Tooltip>
              </h1>
              <Badge variant='info-light' style={{ height: '20px', width: '126px' }}>
                {getFormattedRole(profileData?.role)}
              </Badge>
            </div>
          </Header>
          <Body>
            <div className='section__title'>
              <div>
                <h1 style={{ marginBottom: 0 }}>
                  <Text lineHeight='md' textStyle='titleSmBold'>
                    {SectionText.section.company.body.firstTitle}
                  </Text>
                </h1>
              </div>
              <div>
                <button onClick={() => setIsOpen(true)} className='section__item-icon' style={{ width: '100%', marginRight: 0 }}>
                  <img src={iconPencil} alt='edit Icon' className='section__icon-edit' id='edit-open-drawer' />
                </button>
              </div>
            </div>
            <div className='section__item'>
              <div className='section__item-icon'>
                <img src={iconPhone} alt='Tel Icon' />
              </div>
              <div className='section__item-data-phone'>
                <Text className='section__item-title' textStyle='labelSm'>
                  {SectionText.section.company.body.phoneLabel}
                </Text>
                <div className='section__item-value-phone'>
                  {isValidData(profileData?.phoneNumber)
                    ? `+${profileData?.countryCode}${profileData?.phoneNumber}`
                    : 'No tenés un número asociado'}
                </div>
              </div>
            </div>
            <div className='section__item'>
              <div
                className={
                  emailBadges?.hasBadge && (emailBadges?.badge === 'editado' || emailBadges?.badge === 'enviado')
                    ? 'section__item-icon-send'
                    : 'section__item-icon'
                }
              >
                <img src={iconMail} alt='Mail Icon' />
              </div>
              <div className='section__item-data'>
                <Text className='section__item-title' textStyle='labelSm'>
                  {SectionText.section.company.body.emailLabel}
                </Text>
                <Tooltip
                  hasArrow
                  label={profileData?.operatorEmail}
                  isDisabled={!hasTooltip(emailRef)}
                  backgroundColor='#2F3034'
                  padding='8px'
                  borderRadius='8px'
                  fontFamily='Inter'
                  fontSize='12px'
                  fontWeight={400}
                  textAlign='center'
                >
                  <div ref={emailRef} className='section__item-value'>
                    {profileData?.operatorEmail}
                  </div>
                </Tooltip>
              </div>
            </div>
          </Body>
          <Footer>
            <Button
              variant='link'
              className='section__button'
              id='profile-button-edit' /* onClick={() => console.log('logout') logout()} */
            >
              {/* <img src={iconLogout} alt='Logout Icon' className='section__button-icon' /> */}
              {SectionText.section.profile.footer.button}
            </Button>
          </Footer>
        </>
      )}
      <DrawerOpComponent isOpen={isOpen} onClose={onClose} data={drawerData} />
    </Container>
  );
}

export default CompanySection;
