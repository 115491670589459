import { TEXT_STEP_FOUR, TEXT_STEP_ONE, TEXT_STEP_THREE, TEXT_STEP_TWO } from './constants';

const wizardSteps = [
  {
    title: TEXT_STEP_ONE.title,
    description: TEXT_STEP_ONE.description,
    target: 'profile-card-info-personal',
    placement: 'left',
    offsetExtraY: 73,
    offsetExtraX: 16,
  },
  {
    title: TEXT_STEP_TWO.title,
    description: TEXT_STEP_TWO.description,
    target: 'profile-card-info-company',
    placement: 'left',
    offsetExtraY: 9,
    offsetExtraX: 16,
  },
  {
    title: TEXT_STEP_THREE.title,
    description: TEXT_STEP_THREE.description,
    target: 'profile-card-info-password',
    placement: 'left',
    offsetExtraY: 9,
    offsetExtraX: 16,
  },
  {
    title: TEXT_STEP_FOUR.title,
    description: TEXT_STEP_FOUR.description,
    target: 'profile-card-info-token',
    placement: 'right',
    offsetExtraY: 9,
    offsetExtraX: -16,
  },
];

export default wizardSteps;
