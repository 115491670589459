import styled from 'styled-components';

const Container = styled.div`
  width: 373px;
  height: 100%;
  padding: 24px;
  gap: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .error__icon {
    width: 80px;
    height: 80px;
    margin-bottom: 16px;
  }

  .error__title {
    font-family: Red Hat Display;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    text-align: center;
    margin-bottom: 8px;
  }

  .error__description {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
    text-align: center;
  }

  .error__button {
    font-family: Inter;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.10000000149011612px;
    text-align: center;
    color: #2172dc;
    text-decoration: underline;
    margin-top: 27px;
  }

  .error__button:hover,
  .error__button[data-hover] {
    color: rgb(74, 143, 251);
  }

  .error__button:active,
  .error__button[data-active] {
    color: rgb(0, 91, 191);
  }
`;

export default Container;
