/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable import/no-unresolved */
import { HeaderBase, useAuthorizer } from '@react-ib-mf/header-menu-ui';
import { isCheckOTP } from '@react-ib-mf/style-guide-ui';
import { ToastComponent } from '@wow/toast';
import { useContext, useEffect, useState } from 'react';

import AlertBanner from '../components/AlertBanner/AlertBanner';
import DrawerAddEmail from '../components/DrawerAddComponent/DrawerAddEmail';
import ModalComponent from '../components/Modal/Modal';
import ModalState from '../components/ModalState';
import ModalToken from '../components/ModalToken';
import CompanySection from '../components/Section/CompanySection/CompanySection';
import ProfileSection from '../components/Section/ProfileSection/ProfileSection';
import SimpleSection from '../components/Section/SimpleSection/SimpleSection';
import WelcomeWizardModal from '../components/WelcomeWizardModal';
import { IEmailValidation } from '../interfaces/IProfile';
import perfilText from '../lang/es.json';
import { AppContext } from '../providers/AppContextProvider';
import isValidData from '../utils/validateData';
import { SectionContainer, Wrapper } from './styled';

function Perfil() {
  /* DOC: Contexto global, profileData: Obtiene data del perfil del operador + la persona, esta variable obtiene los valores xq internamente llama al service. */
  /* DOC: Contexto global, drawerAddEmail/setDrawerAddEmail: es un estado con una estrutura interna: 
  {
    isOpenDrawer: false,
    emailPerson: '',
    isLoadingBtn: false,
    isOpenModalToken: false,
    isOpenModalConfirmation: false,
    isOpenModalValidation: false,
  }. 
  accediento a drawerAddEmail podemos acceder a los valores de la estructura y con setDrawerAddEmail ir cambiando los estados para ir mostrando una u otra cosa o realizar una accion.
  */
  const {
    profileData,
    drawerAddEmail,
    setDrawerAddEmail,
    setResendEmail,
    isLoadingResend,
    AddEmailPersonAsync,
    setIsDrawerOpenEmail,
    resendEmail,
  } = useContext(AppContext) as any;
  const [showAlert, setShowAlert] = useState(true);
  const [isOpenModalToken, setIsOpenModalToken] = useState(false);
  const [emailValidation, setEmailValidation] = useState<IEmailValidation>({
    statusAlert: false,
    buttonLabel: '',
    hasBadge: false,
    badge: '',
    title: '',
    description: '',
  });
  const [{ logout }] = useAuthorizer();

  useEffect(() => {
    const items = sessionStorage.getItem('PERSONA_BLOQUEADA');
    if (items) {
      logout();
    }
  }, []);

  const checkEmailValidation = emailStatus => {
    // DOC: No tiene email -> alerta y badge de agrega tu email
    if (!isValidData(profileData.personEmail)) {
      return {
        statusAlert: true,
        buttonLabel: 'Agregar email',
        hasBadge: false,
        badge: 'Agregar email',
        title: 'Necesitás agregar tu email personal',
        description: 'Para garantizar la seguridad de tus operaciones necesitamos que agregues tu email personal.',
      };
    }

    // DOC: Tiene OTP, tiene email status === confirmado -> alerta y badge validar email
    if (isCheckOTP() && isValidData(emailStatus) && emailStatus.toLowerCase() === 'confirmado') {
      return {
        statusAlert: true,
        buttonLabel: perfilText.alert.buttonLabelValidate,
        hasBadge: false,
        badge: perfilText.alert.badge.state_1,
        title: perfilText.alert.titleValidate,
        description: perfilText.alert.descriptionValidate,
      };
    }

    /*
    // DOC: Tiene OTP y no tiene email validado -> alerta y badge de validar email
    if (isCheckOTP() && !isValidData(emailStatus) && emailStatus.toLowerCase() !== 'validado') {
      return {
        statusAlert: true,
        buttonLabel: perfilText.alert.buttonLabelValidate,
        hasBadge: false,
        badge: perfilText.alert.badge.state_1,
        title: perfilText.alert.titleValidate,
        description: perfilText.alert.descriptionValidate,
      };
    } 
    // DOC: No Tiene OTP y no tiene email status, es null -> muestra alerta y btn -> confirmar email
    if (!isCheckOTP() && !isValidData(emailStatus)) {
      return {
        statusAlert: false,
        buttonLabel: perfilText.alert.buttonLabelConfirm,
        hasBadge: false,
        badge: perfilText.alert.badge.state_4,
        title: perfilText.alert.titleConfirm,
        description: perfilText.alert.descriptionConfirm,
      };
    }

    // DOC: No Tiene OTP y tiene email status y es editado o enviado -> muestra alerta y btn -> por confirmar email
    if (!isCheckOTP() && isValidData(emailStatus) && (emailStatus.toLowerCase() === 'editado' || emailStatus.toLowerCase() === 'enviado')) {
      return {
        statusAlert: false,
        buttonLabel: perfilText.alert.buttonLabelConfirm,
        hasBadge: false,
        badge: perfilText.alert.badge.state_4,
        title: perfilText.alert.titleConfirm,
        description: perfilText.alert.descriptionConfirm,
      };
    } 
    

    // DOC: No Tiene OTP y no tiene email status, es null -> muestra alerta y btn -> confirmar email
    if (!isCheckOTP() && !isValidData(emailStatus)) {
      return {
        statusAlert: true,
        buttonLabel: perfilText.alert.buttonLabelConfirm,
        hasBadge: false,
        badge: perfilText.alert.badge.state_4,
        title: perfilText.alert.titleConfirm,
        description: perfilText.alert.descriptionConfirm,
      };
    }
*/

    // DOC: Por defecto se limpian los estados y se pone en true una propiedad hasBadge: true, para que comience a mostrar estados de badge (validado, confirmado, por confirmar, por validar)
    if (isValidData(emailStatus)) {
      return {
        statusAlert: false,
        buttonLabel: perfilText.alert.buttonLabelConfirm,
        hasBadge: true,
        badge: emailStatus?.toLowerCase(),
        title: '',
        description: '',
      };
    }

    return emailValidation;
  };

  /* DOC: openModal: Abre drawer para dar de alta un email sino abre modal de edicion  */
  const openModal = () => {
    if (!isValidData(profileData.personEmail)) {
      handleOpenDrawerAddEmail();
    } else if (isCheckOTP()) {
      setIsOpenModalToken(true);
    } else {
      setResendEmail(true);
    }
  };

  const setIsOpenModalTokenFalse = () => {
    setIsOpenModalToken(false);
  };

  const closeModal = () => {
    setIsOpenModalToken(false);
    setShowAlert(false);
  };

  /* DOC: Manejo de estados del drawer para dar de alta un email personal con OTP o sin OTP */
  /* DOC: se usa un contexto global para ir cambiando los estados, se valida si tiene otp,
  abre drawer de token sino tiene otp no lo abre y para ambos casos luego envia un email y se abre una pantalla con el timer de 5m */
  const handleCloseDrawerAddEmail = () => {
    setDrawerAddEmail({ ...drawerAddEmail, isOpenDrawer: false });
  };

  const handleOpenDrawerAddEmail = () => {
    setDrawerAddEmail({ ...drawerAddEmail, personEmail: '', isOpenDrawer: true });
  };

  const handleSubmitDrawerAddEmail = (email: string) => {
    if (!isCheckOTP()) {
      AddEmailPersonAsync({ email, hasOtp: false });
    }
    if (isCheckOTP()) {
      setDrawerAddEmail({ ...drawerAddEmail, personEmail: email, isOpenDrawer: false, isOpenModalToken: true });
    }
  };

  /* DOC: Si tiene OTP,  se obtiene el valor del token escrito por el usuario para luego enviarlo enconjunto al email al servicio, 
  luego se abre pantalla con timer de 5m. Tambien hay una funcion hableCloseModalToken para poder cerrarlo  */
  const getValueTokenPinInput = (valueToken: string) => {
    if (isCheckOTP()) {
      console.log('TOKEN', valueToken);
      console.log('TIENE OTP - ABRIR MODAL TOKEN - PEDIR TOKEN - ENVIAR POR POST EL EMAIL CON VALOR DE TOKEN');
      AddEmailPersonAsync({ email: drawerAddEmail.personEmail, hasOtp: true, token: valueToken });
    }
  };

  const handleOpenModalToken = () => {
    setDrawerAddEmail({ ...drawerAddEmail, isOpenDrawer: false, isOpenModalToken: true, isLoadingBtn: false, isOpenModalError: false });
  };

  const handleCloseModalToken = () => {
    setDrawerAddEmail({ ...drawerAddEmail, isOpenDrawer: false, isOpenModalToken: false, isLoadingBtn: false });
  };

  /* DOC:  OnCloseModalSendEmail -> funcion para cerrar el modal de te enviamos un link */
  const handleCloseModalValidationSendEmail = () => {
    setDrawerAddEmail({
      ...drawerAddEmail,
      isLoadingBtn: false,
      isOpenModalToken: false,
      isOpenDrawer: false,
      isOpenModalValidation: false,
    });
  };

  const handleCloseModalConfirmationSendEmail = () => {
    setDrawerAddEmail({
      ...drawerAddEmail,
      isLoadingBtn: false,
      isOpenModalToken: false,
      isOpenDrawer: false,
      isOpenModalConfirmation: false,
    });
  };

  /* DOC:  OnCloseModalSendEmail -> funcion para cerrar el modal de te enviamos un link */
  const handleCloseModalError = () => {
    setDrawerAddEmail({
      ...drawerAddEmail,
      isOpenModalError: false,
    });
  };

  const handleOpenDrawerUpdate = () => {
    setIsDrawerOpenEmail(true);
    setDrawerAddEmail({
      ...drawerAddEmail,
      isOpenModalValidation: false,
      isOpenModalConfirmation: false,
    });
  };

  const handleResendEmail = () => {
    setDrawerAddEmail({
      ...drawerAddEmail,
      isOpenModalValidation: false,
      isOpenModalConfirmation: false,
    });
    setResendEmail(true);
  };

  useEffect(() => {}, [drawerAddEmail, setDrawerAddEmail]);

  useEffect(() => {
    if (profileData) {
      setEmailValidation(checkEmailValidation(profileData.emailStatus));
    }
  }, [profileData]);

  return (
    <Wrapper id='profile'>
      <HeaderBase idHeader='profile-title' title={perfilText.title} breadcrumb={perfilText.breadcrumbOptions} customStyle='fit-content' />
      <WelcomeWizardModal />
      {emailValidation.statusAlert && (
        <AlertBanner
          buttonLabel={emailValidation.buttonLabel}
          title={emailValidation.title}
          description={emailValidation.description}
          openModal={openModal}
          showAlert={showAlert}
        />
      )}

      <SectionContainer>
        <ProfileSection
          id='profile-card-info-personal'
          isOpenModalTokenFromAlert={isOpenModalToken}
          closeModalFromAlert={closeModal}
          setIsOpenModalTokenFalse={setIsOpenModalTokenFalse}
          handleOpenDrawerAddEmail={handleOpenDrawerAddEmail}
        />
        <div className='section__companySection'>
          <CompanySection
            title={profileData?.companyName}
            emailBadges={emailValidation}
            isOpenModalTokenFromAlert={isOpenModalToken}
            closeModalFromAlert={closeModal}
            setIsOpenModalTokenFalse={setIsOpenModalTokenFalse}
            id='profile-card-info-company'
          />
          <SimpleSection
            title={perfilText.section.password.header.title}
            sectionTitle={perfilText.section.password.body.firstTitle}
            description={perfilText.section.password.header.description}
            hasDate
            buttonText={perfilText.section.password.footer.button}
            id='profile-card-info-password'
            url={perfilText.section.password.urlComplete}
            openMode={perfilText.section.password.openMode}
          />
        </div>
        <div className='section__simpleSection'>
          <SimpleSection
            title={perfilText.section.token.header.title}
            sectionTitle={perfilText.section.token.body.firstTitle}
            description={perfilText.section.token.header.description}
            descriptionSecond={perfilText.section.token.header.description_secondary}
            buttonText={perfilText.section.token.footer.button}
            hasDate={false}
            hasIcon
            id='profile-card-info-token'
            url={perfilText.section.token.urlComplete}
            openMode={perfilText.section.token.openMode}
          />
        </div>
      </SectionContainer>

      {/* DOC: DrawerAddEmail -> props: isOpen, closeDrawer, submit, isLoadingBtn, internamente tiene textos para Agregar el email, podriamos extenderlo y dejar un solo drawer */}
      <DrawerAddEmail
        isOpen={drawerAddEmail.isOpenDrawer}
        closeDrawer={handleCloseDrawerAddEmail}
        submit={(e: any) => handleSubmitDrawerAddEmail(e)}
        isLoadingBtn={drawerAddEmail.isLoadingBtn}
        errorDrawer={isValidData(drawerAddEmail.errorResponse)}
      />
      <ModalState
        title={drawerAddEmail.errorResponse.title}
        typeModal={drawerAddEmail.errorResponse.typeModal}
        description={drawerAddEmail.errorResponse.description}
        isOpen={drawerAddEmail.isOpenModalError}
        onClose={handleCloseModalError}
        buttonTextPrimary='Entendido'
        handleClickButtonPrimary={drawerAddEmail.errorResponse.typeModal === 'warning' ? handleOpenModalToken : handleCloseModalError}
        isLogout={drawerAddEmail.errorResponse.isLogout}
      />

      <ModalToken
        title={perfilText.modal.token.title}
        description={perfilText.modal.token.subtitle}
        showModal={drawerAddEmail.isOpenModalToken}
        handleCloseModal={() => handleCloseModalToken()}
        returnValueToken={(tokenValue: string) => getValueTokenPinInput(tokenValue)}
        isLoading={drawerAddEmail.isLoadingBtn}
        errorToken={drawerAddEmail.errorToken}
      />
      {
        /* DOC: ModalComponet -> podemos usarlo para modal confirmation y validation, se abre modal con timer de 5m y dependiendo de si tiene otp o no muestra otro texto */
        isCheckOTP() ? (
          <ModalComponent
            isOpen={drawerAddEmail.isOpenModalValidation}
            onClose={() => handleCloseModalValidationSendEmail()}
            updateEmail={() => handleOpenDrawerUpdate()}
            resendEmail={() => handleResendEmail()}
            email={drawerAddEmail.emailPerson}
          />
        ) : (
          <ModalComponent
            isOpen={drawerAddEmail.isOpenModalConfirmation}
            onClose={() => handleCloseModalConfirmationSendEmail()}
            updateEmail={() => handleOpenDrawerUpdate()}
            resendEmail={() => handleResendEmail()}
            email={drawerAddEmail.emailPerson}
          />
        )
      }
      <div className='loading-resend-email-not-otp'>
        {isLoadingResend && resendEmail && <ToastComponent status='info' description='Reenviando el email de confirmacion.' />}
      </div>
    </Wrapper>
  );
}

export default Perfil;
