/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable consistent-return */
/* eslint-disable no-else-return */
/* eslint-disable react-hooks/exhaustive-deps */
// eslint-disable-next-line import/no-unresolved

import { createContext, useMemo, useState } from 'react';
import { useQuery } from 'react-query';

import { IEmailPersonProps, IPostAddEmailPerson } from '../interfaces/IEmailPerson';
import { getPerfilMenu, getProfile, postAddEmailPerson } from '../services/api';
import handleErrorsAddEmailPerson from '../utils/handleErrorsAddEmailPerson';

export const AppContext = createContext({});
export default function AppContextProvider({ children }: any) {
  const [profileData, setProfileData] = useState(null);
  const [profileStatus, setProfileStatus] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingTokenOtp, setIsLoadingTokenOtp] = useState(false);
  const [isDrawerOpenEmail, setIsDrawerOpenEmail] = useState(false);
  const [resendEmail, setResendEmail] = useState(false);
  const [isLoadingResend, setIsLoadingResend] = useState(false);
  const [drawerComponentEmail, setDrawerComponentEmail] = useState({ errorToken: false });
  const [drawerAddEmail, setDrawerAddEmail] = useState<IEmailPersonProps>({
    isOpenDrawer: false,
    emailPerson: '',
    isLoadingBtn: false,
    isOpenModalToken: false,
    token: '',
    isOpenModalValidation: false,
    isOpenModalConfirmation: false,
    errorResponse: '',
    isOpenModalError: false,
    dataResponse: '',
    errorToken: false,
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { isError: isErrorFetchMenuProfile } = useQuery('perfil', () => getPerfilMenu().then(res => res.data.data));

  const fetchDataAndUpdateProfile = async () => {
    try {
      const profileResponse = await getProfile();

      if (profileResponse.status === 200) {
        setProfileData(profileResponse.data.data);
        setProfileStatus(200);
      }

      return profileResponse.data;
    } catch (err) {
      const codeErrorService = err.response?.status || 500;
      setProfileStatus(codeErrorService);
    } finally {
      setIsLoading(false);
    }
  };

  const AddEmailPersonAsync = async ({ email, hasOtp, token }: IPostAddEmailPerson) => {
    setDrawerAddEmail({ ...drawerAddEmail, isLoadingBtn: true });
    try {
      const response = await postAddEmailPerson({ email, hasOtp, token });

      if (response.status === 200) {
        setDrawerAddEmail({
          ...drawerAddEmail,
          dataResponse: response.data.data,
          isLoadingBtn: false,
          isOpenDrawer: false,
          isOpenModalToken: false,
          isOpenModalConfirmation: hasOtp === false,
          isOpenModalValidation: hasOtp === true,
          emailPerson: email,
        });
        await fetchDataAndUpdateProfile();
      }

      return response.data;
    } catch (err) {
      const { errorCode, errorKey, errorMessage } = err?.response?.data?.exception ?? {
        errorCode: null,
        errorKey: null,
        errorMessage: null,
      };
      // TODO : Ver caso de cuando no hay errorCode y errorKey y pasarle el email para pintarlo en el error key 4033
      const dataTextsForModalErrors = handleErrorsAddEmailPerson({ errorCode, errorKey, errorMessage, emailPerson: email });
      if (errorCode === '401' && errorMessage === 'OTP_INVALIDO') {
        setDrawerAddEmail({
          ...drawerAddEmail,
          errorResponse: dataTextsForModalErrors,
          isOpenModalError: false,
          isLoadingBtn: false,
          isOpenDrawer: false,
          isOpenModalToken: true,
          errorToken: true,
          emailPerson: '',
        });
      } else {
        setDrawerAddEmail({
          ...drawerAddEmail,
          errorResponse: dataTextsForModalErrors,
          isOpenModalError: true,
          isLoadingBtn: false,
          isOpenDrawer: false,
          isOpenModalToken: false,
          emailPerson: '',
        });
      }
    }
  };

  const value = useMemo(
    () => ({
      profileData,
      setProfileData,
      profileStatus,
      setProfileStatus,
      fetchDataAndUpdateProfile,
      isLoading,
      setIsLoading,
      isLoadingTokenOtp,
      setIsLoadingTokenOtp,
      isDrawerOpenEmail,
      setIsDrawerOpenEmail,
      drawerAddEmail,
      setDrawerAddEmail,
      resendEmail,
      setResendEmail,
      isLoadingResend,
      setIsLoadingResend,
      AddEmailPersonAsync,
      drawerComponentEmail,
      setDrawerComponentEmail,
    }),
    [
      profileData,
      setProfileData,
      profileStatus,
      setProfileStatus,
      fetchDataAndUpdateProfile,
      isLoading,
      setIsLoading,
      isLoadingTokenOtp,
      setIsLoadingTokenOtp,
      isDrawerOpenEmail,
      setIsDrawerOpenEmail,
      drawerAddEmail,
      setDrawerAddEmail,
      resendEmail,
      setResendEmail,
      isLoadingResend,
      setIsLoadingResend,
      AddEmailPersonAsync,
      drawerComponentEmail,
      setDrawerComponentEmail,
    ],
  );
  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}
