/* eslint-disable import/no-unresolved */
/* eslint-disable consistent-return */
import { Avatar, Button, Spinner, Text, Tooltip } from '@chakra-ui/react';
import { isCheckOTP } from '@react-ib-mf/style-guide-ui';
import { Badge } from '@wow/badge';
import { useContext, useEffect, useRef, useState } from 'react';

import iconCard from '../../../assets/icons/card.svg';
import iconCheck from '../../../assets/icons/check-circle.svg';
import iconGlobe from '../../../assets/icons/globe.svg';
import iconLogout from '../../../assets/icons/log-out.svg';
import iconMail from '../../../assets/icons/mail.svg';
import iconPencil from '../../../assets/icons/pencil.svg';
import iconSend from '../../../assets/icons/send.svg';
import SectionText from '../../../lang/es.json';
import { AppContext } from '../../../providers/AppContextProvider';
import { getAvatarName } from '../../../utils/names';
import isValidData from '../../../utils/validateData';
import DrawerEmail from '../../DrawerComponent/DrawerEmail';
import ErrorState from '../../ErrorState/ErrorState';
import { Body, BodyEmpty, Container, Footer, Header } from './styled';

function ProfileSection({ id, isOpenModalTokenFromAlert, closeModalFromAlert, setIsOpenModalTokenFalse, handleOpenDrawerAddEmail }: any) {
  const {
    profileData,
    profileStatus,
    fetchDataAndUpdateProfile,
    isLoading,
    setIsLoading,
    isDrawerOpenEmail,
    setIsDrawerOpenEmail,
    resendEmail,
    setResendEmail,
  } = useContext(AppContext) as any;
  const [drawerData, setDrawerData] = useState({ phoneNumber: '', email: '', countryCode: '' });
  const onCloseDrawerEmail = () => setIsDrawerOpenEmail(false);
  const handleReOnOpenEmail = () => setIsDrawerOpenEmail(true);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const nameRef = useRef(null);
  const emailRef = useRef(null);

  const hasTooltip = ref => {
    return ref?.current?.offsetWidth < ref?.current?.scrollWidth;
  };

  useEffect(() => {
    fetchDataAndUpdateProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setDrawerData({
      phoneNumber: profileData?.phoneNumber ?? '',
      email: profileData?.personEmail,
      countryCode: profileData?.countryCode?.toString(),
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileData]);

  const handleUpdateProfile = () => {
    setIsLoading(true);
    fetchDataAndUpdateProfile();
  };

  const getBadgeType = badgeType => {
    if (badgeType === 'validado') {
      return (
        <Badge variant='success-light'>
          <img src={iconCheck} alt='check Icon' />
          {SectionText.alert.badge.state_2}
        </Badge>
      );
    }
    // RESEND EMAIL
    if (badgeType === 'editado' || badgeType === 'enviado') {
      return (
        <>
          <Badge variant='warning-light'>{isCheckOTP() ? SectionText.alert.badge.state_3 : SectionText.alert.badge.state_6}</Badge>
          <Tooltip
            hasArrow
            placement='right'
            label={isCheckOTP() ? SectionText.alert.badge.sendValidation_label : SectionText.alert.badge.sendConfirm_label}
            backgroundColor='#2F3034'
            padding='8px'
            borderRadius='8px'
            fontFamily='Inter'
            fontSize='12px'
            fontWeight={400}
            textAlign='center'
            isOpen={tooltipVisible}
            isDisabled={!tooltipVisible}
          >
            <Button
              id='profile-button-send'
              className='section__button-badge'
              onMouseEnter={() => setTooltipVisible(true)}
              onMouseLeave={() => setTooltipVisible(false)}
              onClick={() => {
                setResendEmail(true);
                setTooltipVisible(false);
              }}
              sx={{ ':focus-visible': { background: 'transparent', boxShadow: 'none' } }}
            >
              <img src={iconSend} alt='send Icon' className='' />
            </Button>
          </Tooltip>
        </>
      );
    }
    if (badgeType === 'confirmado' && !isCheckOTP()) {
      return <Badge variant='success-light'>{SectionText.alert.badge.state_5}</Badge>;
    }
    if (badgeType === 'confirmado' && isCheckOTP()) {
      return (
        <Button variant='link' className='section__button' onClick={() => handleClickForBadgeType()}>
          <Text textStyle='labelSmBold'>Validar email</Text>
        </Button>
      );
    }
  };

  const editEmail = () => {
    setIsDrawerOpenEmail(true);
  };

  // DOC: BUGCITO DE CERRAR ALTER VALIDAR AHORA + VALIDAR EMAIL DESDE BTN LINK
  const checkResendEmail = () => {
    setResendEmail(false);
  };

  const handleClickForBadgeType = () => {
    if (!isValidData(profileData.personEmail)) {
      console.log('drawer sin email');
      handleOpenDrawerAddEmail();
    } else {
      console.log('drawer editando');
      setResendEmail(true);
    }
  };

  return (
    <Container id={id}>
      {isLoading && (
        <BodyEmpty>
          <div className='section__title'>
            <h1>{SectionText.section.profile.body.firstTitle}</h1>
          </div>
          <div className='section__spinner'>
            <Spinner thickness='4px' speed='0.45s' emptyColor='gray.200' color='rgb(74, 143, 251)' size='xl' />
          </div>
        </BodyEmpty>
      )}
      {!isLoading && !profileData && (profileStatus === 400 || profileStatus === 401 || profileStatus === 409 || profileStatus === 404) && (
        <BodyEmpty>
          <div className='section__title'>
            <h3>{SectionText.section.profile.body.firstTitle}</h3>
          </div>
          <ErrorState />
        </BodyEmpty>
      )}
      {!isLoading && !profileData && (profileStatus === 500 || profileStatus === 0) && (
        <BodyEmpty>
          <div className='section__title'>
            <h3>{SectionText.section.profile.body.firstTitle}</h3>
          </div>
          <ErrorState hasButton buttonAction={handleUpdateProfile} />
        </BodyEmpty>
      )}
      {!isLoading && profileData && (
        <>
          <Header>
            <Avatar
              name={getAvatarName(profileData?.firstName, profileData?.lastName)}
              className='section__avatar'
              bg='var(--chakra-colors-primary-500)'
              textColor='white'
            />
            <Tooltip
              hasArrow
              label={`${profileData?.firstName} ${profileData?.lastName}`}
              isDisabled={!hasTooltip(nameRef)}
              backgroundColor='#2F3034'
              padding='8px'
              borderRadius='8px'
              fontFamily='Inter'
              fontSize='12px'
              fontWeight={400}
              textAlign='center'
            >
              <Text className='section__name' ref={nameRef} textStyle='h4Bold'>{`${profileData?.firstName} ${profileData?.lastName}`}</Text>
            </Tooltip>
          </Header>
          <Body>
            <div className='section__title'>
              <div>
                <h1>
                  <Text textStyle='titleLg' style={{ fontWeight: 'bold', width: '100%' }}>
                    {SectionText.section.profile.body.firstTitle}
                  </Text>
                </h1>
              </div>
              {isValidData(profileData.personEmail) && (
                <div>
                  <button
                    onClick={() => editEmail()}
                    className='section__item-icon'
                    style={{ marginTop: -10, width: '100%', marginLeft: '10px' }}
                  >
                    <img src={iconPencil} alt='edit Icon' className='section__icon-edit' id='edit-open-drawer' />
                  </button>
                </div>
              )}
            </div>
            <div className='section__item'>
              <div className='section__item-icon'>
                <img src={iconGlobe} alt='Globe Icon' />
              </div>
              <div className='section__item-data'>
                <Text className='section__item-title' textStyle='labelSm'>
                  {SectionText.section.profile.body.countryLabel}
                </Text>
                <div className='section__item-value'>{profileData?.country}</div>
              </div>
            </div>
            <div className='section__item'>
              <div className='section__item-icon'>
                <img src={iconCard} alt='ID Icon' />
              </div>
              <div className='section__item-data'>
                <Text className='section__item-title' textStyle='labelSm'>
                  {SectionText.section.profile.body.IdLabel}
                </Text>
                <div className='section__item-value'>{profileData?.cuil}</div>
              </div>
            </div>

            <div className='section__item'>
              <div
                className={
                  profileData.emailStatus &&
                  (profileData.emailStatus.toLowerCase() === 'editado' || profileData.emailStatus.toLowerCase() === 'enviado')
                    ? 'section__item-icon-send'
                    : 'section__item-icon'
                }
              >
                <img src={iconMail} alt='Mail Icon' />
              </div>
              <div className='section__item-data'>
                <Text className='section__item-title' textStyle='labelSm'>
                  {SectionText.section.profile.body.emailLabel}
                </Text>
                <Tooltip
                  hasArrow
                  label={profileData?.personEmail}
                  isDisabled={!hasTooltip(emailRef)}
                  backgroundColor='#2F3034'
                  padding='8px'
                  borderRadius='8px'
                  fontFamily='Inter'
                  fontSize='12px'
                  fontWeight={400}
                  textAlign='center'
                >
                  <div ref={emailRef} className='section__item-value'>
                    {profileData?.personEmail}
                  </div>
                </Tooltip>
              </div>

              <div
                className={
                  profileData.emailStatus &&
                  (profileData.emailStatus.toLowerCase() === 'editado' || profileData.emailStatus.toLowerCase() === 'enviado')
                    ? 'section__item-badge-icon'
                    : 'section__item-badge'
                }
              >
                {isValidData(profileData.emailStatus) && isValidData(profileData.personEmail) ? (
                  getBadgeType(profileData.emailStatus.toLowerCase())
                ) : (
                  <Button variant='link' className='section__button' onClick={() => handleClickForBadgeType()}>
                    <Text textStyle='labelSmBold'>{!isValidData(profileData.personEmail) && 'Agregar email'}</Text>
                    <Text textStyle='labelSmBold'>{isValidData(profileData.personEmail) && isCheckOTP() && 'Validar email'}</Text>
                    <Text textStyle='labelSmBold'>{isValidData(profileData.personEmail) && !isCheckOTP() && 'Confirmar email'}</Text>
                  </Button>
                )}
              </div>
            </div>
          </Body>

          <Footer>
            <Button
              variant='link'
              className='section__button'
              id='profile-button-edit' /* onClick={() => console.log('logout') logout()} */
            >
              <img src={iconLogout} alt='Logout Icon' className='section__button-icon' />
              {SectionText.section.profile.footer.button}
            </Button>
          </Footer>
        </>
      )}
      <DrawerEmail
        isOpen={isDrawerOpenEmail}
        onClose={onCloseDrawerEmail}
        data={drawerData}
        handleReOpen={() => {
          handleReOnOpenEmail();
        }}
        resendEmailButton={resendEmail}
        checkResendEmail={checkResendEmail}
        isOpenModalTokenFromAlert={isOpenModalTokenFromAlert}
        closeModalFromAlert={closeModalFromAlert}
        isOpenModalTokenFalse={setIsOpenModalTokenFalse}
      />
    </Container>
  );
}

export default ProfileSection;
