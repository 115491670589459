import './styled/fonts';

import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { createStandaloneToast } from '@chakra-ui/toast';
// eslint-disable-next-line import/no-unresolved
import { Fonts, PrivateRender, theme as ThemeData } from '@react-ib-mf/style-guide-ui';
import { AlertChakra } from '@wow/alert';
import { BadgeChakra } from '@wow/badge';
import { ButtonChakra } from '@wow/button';
import { CloseButtonChakra } from '@wow/close-button';
import { DrawerChakra } from '@wow/drawer';
import { ModalChakra } from '@wow/modal';
import { PinInputChakra } from '@wow/pin-input';
// eslint-disable-next-line import/no-unresolved
import { themeChakra } from '@wow/themes';
import { ToastComponent } from '@wow/toast';
import { TooltipChakra } from '@wow/tooltip';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import App from './App';

const { ToastContainer } = createStandaloneToast();

const theme = extendTheme({
  ...ThemeData,
  components: {
    Alert: AlertChakra,
    CloseButton: CloseButtonChakra,
    Badge: BadgeChakra,
    Button: ButtonChakra,
    Drawer: DrawerChakra,
    Modal: ModalChakra,
    PinInput: PinInputChakra,
    Tooltip: TooltipChakra,
    Toast: ToastComponent,
  },
  textStyles: {
    ...themeChakra.textStyles,
  },

  styles: {
    ...themeChakra.styles,
  },

  colors: { ...themeChakra.colors },
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      retry: 2,
      retryDelay: attempt => attempt * 1000,
    },
  },
});

export default function Root() {
  return (
    <PrivateRender mode={process.env.NODE_ENV}>
      <QueryClientProvider client={queryClient}>
        <ChakraProvider resetCSS theme={theme}>
          <Fonts />
          <App />
          <ToastContainer />
        </ChakraProvider>
        <ReactQueryDevtools />
      </QueryClientProvider>
    </PrivateRender>
  );
}
