/* eslint-disable import/no-unresolved */

import iconBuild from '../../assets/icons/under-construction.svg';
import { IErrorState } from '../../interfaces/IProfile';
import SectionText from '../../lang/es.json';
import Container from './styled';

function ErrorState({ hasButton, buttonAction }: IErrorState) {
  return (
    <Container id='Error-perfil'>
      <img src={iconBuild} alt='Error profile icon' className='error__icon' id='error-icon' />
      <h3 className='error__title' id='error-title'>
        {SectionText.section.ErrorState.title}
      </h3>
      <p className='error__description' id='error-description'>
        {SectionText.section.ErrorState.description}
      </p>
      {hasButton && (
        <button className='error__button' onClick={buttonAction}>
          {SectionText.section.ErrorState.buttonText}
        </button>
      )}
    </Container>
  );
}

export default ErrorState;
