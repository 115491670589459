import styled from 'styled-components';

const Container = styled.div`
  width: 405px;
  height: 238px;
  padding: 16px;
  gap: 0px;
  border-radius: 12px;
  border: 1px solid #bec6dc;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Header = styled.div`
  gap: 12px;
  display: flex;
  flex-direction: column;
  gap: initial;

  h3 {
    font-family: Red Hat Display;
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    text-align: left;
  }

  .section__title {
    display: flex;
    gap: 12px;
    justify-content: space-between;
  }

  .section__description {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
    text-align: left;
  }

  .section__description--secondary {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
    text-align: left;
    margin-top: 20px;
  }

  .section__button--help {
    min-width: unset;
    margin-right: 7px;
  }
`;

const BodyEmpty = styled.div`
  h3 {
    font-family: Red Hat Display;
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    text-align: left;
  }

  .section__title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 67px;
  }

  .section__spinner {
    align-item: center;
    display: flex;
    justify-content: center;
  }
`;

const Body = styled.div`
  margin-top: 10px;
  height: 100%;

  h1 {
    font-family: Red Hat Display;
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    text-align: left;
    margin-bottom: 22px;
  }

  .section__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .section__title.contacto {
    padding-top: 9px;
  }

  .section__value {
    font-family: Red Hat Display;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    text-align: left;
  }

  .section__description {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
    text-align: left;
  }

  .section__description--secondary {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
    text-align: left;
    margin-top: 20px;
  }

  .section__button .section__button-icon {
    position: absolute;
    margin-top: -8px;
  }

  .section__item {
    display: flex;
    margin-top: 16px;
    width: max-content;
  }

  .section__icon-edit {
    cursor: pointer;
  }

  .section__item-icon {
    margin-right: 10px;
    display: flex;
    align-item: center;
    justify-content: center;
  }

  .section__item-icon-send {
    margin-right: 10px;
    display: flex;
    align-item: center;
    justify-content: center;
    margin-top: -8px;
  }

  .section__item-data {
    display: flex;
    flex-direction: column;
    width: 320px;
  }

  .section__item-data-phone {
    display: flex;
    flex-direction: column;
  }

  .section__item-title {
    letter-spacing: 0.10000000149011612px;
    text-align: left;
    color: #5e5e61;
  }

  .section__item-value {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .section__item-value-phone {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
    text-align: left;
  }

  .section__dropdown {
    box-shadow: 0px 4px 6px -2px #131c2c0d, 0px 10px 15px -3px #131c2c1a;
    min-width: auto;
    border-radius: 12px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.10000000149011612px;
    text-align: left;
    height: 120px;
    width: 182px;
    border: none;
    margin-top: -20px;
    margin-left: -140px;
    .section__dropdown_item {
      padding: 18px 24px 18px 16px;
      height: 48px;
    }

    .section__dropdown_item:hover {
      background: #f0f0fc;
    }

    .section__dropdown_item:active {
      background: #f8f8f8;
    }
    .section__dropdown_item:focus {
      background: #f0f0fc;
    }
  }

  .section__button {
    font-family: Inter;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.10000000149011612px;
    text-align: center;
    color: #2172dc;
    text-decoration: underline;
  }

  .section__button .section__button-icon {
    margin-right: 7px;
  }

  .section__button:hover,
  .section__button[data-hover] {
    color: rgb(74, 143, 251);
  }

  .section__button:active,
  .section__button[data-active] {
    color: rgb(0, 91, 191);
  }

  .section__button-badge,
  .section__button-badge:hover,
  .section__button-badge[data-hover],
  .section__button-badge:active,
  .section__button-badge[data-active] {
    background: none;
  }

  .section__item-badge {
    width: 135px;
    text-align: right;
    align-content: end;
  }

  .section__item-badge-icon {
    margin-top: -5px;
    padding-left: 5px;
    width: 145px;
    text-align: right;
  }
`;

const Footer = styled.div`
  display: flex;

  .section__button {
    display: none;
    font-family: Inter;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.10000000149011612px;
    text-align: center;
    color: #2172dc;
    text-decoration: underline;
  }

  .section__button .section__button-icon {
    margin-right: 7px;
  }

  .section__button:hover,
  .section__button[data-hover] {
    color: rgb(74, 143, 251);
  }

  .section__button:active,
  .section__button[data-active] {
    color: rgb(0, 91, 191);
  }
`;
export { Body, BodyEmpty, Container, Footer, Header };
