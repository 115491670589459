/* eslint-disable no-unused-expressions */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-children-prop */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-prototype-builtins */
/* eslint-disable import/no-unresolved */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Input } from '@chakra-ui/input';
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormErrorMessage,
  FormLabel,
  InputGroup,
  InputRightElement,
  Text,
} from '@chakra-ui/react';
import { isCheckOTP, useToastOptions } from '@react-ib-mf/style-guide-ui';
import { useContext, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';

import AlertIcon from '../../assets/icons/alert-circle.svg';
import { IDrawerComponentProps, IProfileData } from '../../interfaces/IDrawer';
import { IUser } from '../../interfaces/IUser';
import plainText from '../../lang/es.json';
import { AppContext } from '../../providers/AppContextProvider';
import { patchUpdateProfile, postResendProfile } from '../../services/api';
import { ERROR_MESSAGE_ADD_PERSON_EMAIL } from '../../utils/enums/errorMenssagesAddEmailPerson';
import ModalValidation from '../Modal/ModalValidation';
import ModalState from '../ModalState';
import ModalToken from '../ModalToken';
import { Container, DrawerDescription, DrawerTitle, EmailContainer } from './styled';

function DrawerEmail({
  isOpen,
  onClose,
  data,
  handleReOpen,
  resendEmailButton,
  checkResendEmail,
  isOpenModalTokenFromAlert,
  closeModalFromAlert,
  isOpenModalTokenFalse,
}: IDrawerComponentProps) {
  const [, setToastOptions] = useToastOptions();
  const {
    fetchDataAndUpdateProfile,
    setIsLoading,
    isLoading,
    setIsLoadingTokenOtp,
    setIsLoadingResend,
    resendEmail,
    setResendEmail,
    isLoadingTokenOtp,
    isLoadingResend,
    drawerComponentEmail,
    setDrawerComponentEmail,
  } = useContext(AppContext) as any;
  const [showModalToken, setShowModalToken] = useState(false);
  const [showState, setShowState] = useState(false);
  const [showModalValidation, setShowModalValidation] = useState(false);
  const [dataModalState, setDataModalState] = useState({
    title: '',
    description: '',
    typeModal: '',
    isLogout: false,
  });
  const valueRef = useRef('');

  useEffect(() => {
    if (isOpenModalTokenFromAlert) {
      setResendEmail(true);
      if (isCheckOTP()) {
        setShowModalToken(true);
      } else {
        // VER SI REALMENTE HAY QUE DEJARLO
        postResendEmail();
      }
    }
  }, [isOpenModalTokenFromAlert]);

  const isShowModalToken = isCheckOTP() && (isOpenModalTokenFromAlert || showModalToken);

  const returnValueToken = (dataToken: string) => {
    valueRef.current = dataToken.toString();
    setDrawerComponentEmail({ ...drawerComponentEmail, errorToken: false });
    // setShowModalToken(true);
    if (resendEmail) {
      console.log('ENTRA postResendEmail', dataToken);
      postResendEmail();
    } else {
      console.log('ENTRA patchAndUpdate', dataToken);
      patchAndUpdate();
    }
  };

  const {
    register,
    watch,
    reset,
    getValues,
    formState: { errors, isValid },
  } = useForm<IUser>({
    mode: 'onChange',
    reValidateMode: 'onBlur',
    defaultValues: {
      email: data?.email || '',
    },
  });
  const currentEmail = watch('email');

  useEffect(() => {
    reset({
      email: data?.email || '',
    });
  }, [data?.email, reset]);

  useEffect(() => {
    if (resendEmailButton && isCheckOTP()) {
      setShowModalToken(true);
      setResendEmail(true);
    } else if (resendEmailButton && !isCheckOTP()) {
      postResendEmail();
    }

    // TODO: habiamos comentado el checkresend xq nos inabilitaba un caso, pero resulta que era solo este checkresend no la funcion entera
    // checkResendEmail();
  }, [resendEmailButton]);

  const isDisabledButton = (): boolean => !isValid || currentEmail === data.email;
  // eslint-disable-next-line no-useless-escape

  const handleOnClose = () => {
    reset({
      email: data?.email || '',
    });
    onClose();
  };

  const profileDataModified = (sourceData, modifiedData) => {
    const newData: IProfileData = { countryCode: sourceData?.countryCode.toString(), email: modifiedData?.email };

    return newData;
  };

  const [newData, setNewData] = useState<IProfileData>(data);
  const updateData = () => setNewData(profileDataModified(data, getValues()));

  const setErrorForModalState = (err, dataAux) => {
    if (err.response.data.exception?.errorCode === '400' || err.response.data.exception?.errorCode === '404') {
      if (isCheckOTP()) {
        setDataModalState({
          title: 'No podemos procesar el Token',
          description: 'El servicio no está disponible en este momento. <br /> Volvé a intentar más tarde.',
          typeModal: 'error',
          isLogout: false,
        });
      } else {
        setDataModalState({
          title: 'No podemos procesar tu solicitud',
          description: 'El servicio no está disponible en este momento. <br /> Volvé a intentar más tarde.',
          typeModal: 'error',
          isLogout: false,
        });
      }
    }

    if (
      err.response.data.exception?.errorCode === '401' &&
      err.response.data.exception?.errorMessage === ERROR_MESSAGE_ADD_PERSON_EMAIL.OTP_INVALIDO
    ) {
      setDataModalState({
        title: 'El Token ingresado es incorrecto',
        description: '',
        typeModal: 'error',
        isLogout: false,
      });
    }

    if (
      err.response.data.exception?.errorCode === '401' &&
      err.response.data.exception?.errorMessage === ERROR_MESSAGE_ADD_PERSON_EMAIL.OTP_INVALIDO_ULTIMO_INTENTO
    ) {
      setDataModalState({
        title: 'Estas por bloquear tu usuario',
        description: 'Si volvés a ingresar mal el token, se bloqueará. <br/> Revisá la clave dinámica antes de volver a ingresarla.',
        typeModal: 'warning',
        isLogout: false,
      });
    }

    if (
      err.response.data.exception?.errorCode === '401' &&
      (err.response.data.exception?.errorMessage === ERROR_MESSAGE_ADD_PERSON_EMAIL.PERSONA_BLOQUEADA_INTENTOS ||
        err.response.data.exception?.errorMessage === ERROR_MESSAGE_ADD_PERSON_EMAIL.PERSONA_BLOQUEADA)
    ) {
      setDataModalState({
        title: 'Bloqueamos tu usuario',
        description:
          'Ingresaste mal el Token y por seguriad vamos a cerrar tu sesión. <br/> Podes desbloquear tu usuario en <strong>Gestión de claves</strong>.',
        typeModal: 'error',
        isLogout: true,
      });
    }

    if (err.response.data.exception?.errorCode === '409') {
      if (err.response.data.exception.errorKey === '3028') {
        setDataModalState({
          title: 'No podemos actualizar tus datos',
          description:
            'Cada vez que editás tus datos de contacto, se envía un email. Podés <br /> reenviar ese email hasta 3 veces por día. Ya superaste el límite de hoy,<br />volvé a intentar mañana.',
          typeModal: 'error',
          isLogout: false,
        });
      } else if (err.response.data.exception.errorKey === '3027') {
        setDataModalState({
          title: 'No podemos actualizar tu email',
          description: `Parece que alguien más está usando el correo electrónico ${dataAux.email}. Por favor, elegí otro email.`,
          typeModal: 'error',
          isLogout: false,
        });
      } else if (err.response.data.exception.errorKey === '3025') {
        setDataModalState({
          title: 'No podemos reenviar el email',
          description:
            'Cada vez que editás tus datos de contacto, se envía un email. Podés <br /> reenviar ese email hasta 3 veces por día. Ya superaste el límite de hoy, <br />volvé a intentar mañana.',
          typeModal: 'error',
          isLogout: false,
        });
      } else if (err.response.data.exception.errorKey === '3026') {
        setDataModalState({
          title: 'No podemos actualizar tus datos',
          description: `Podés editar tus datos de contacto solo una vez al día.<br />Ya lo hiciste hoy, volvé a intentar mañana.`,
          typeModal: 'error',
          isLogout: false,
        });
      } else {
        setDataModalState({
          title: 'No podemos procesar el Token',
          description: 'El servicio no está disponible en este momento. <br /> Volvé a intentar más tarde.',
          typeModal: 'error',
          isLogout: false,
        });
      }
    }
    if (err.response.data.exception?.errorCode === '500') {
      if (isCheckOTP()) {
        setDataModalState({
          title: 'No podemos procesar el Token',
          description: 'El servicio no está disponible en este momento. <br /> Volvé a intentar más tarde.',
          typeModal: 'error',
          isLogout: false,
        });
      } else {
        setDataModalState({
          title: 'No podemos procesar tu solicitud',
          description: 'El servicio no está disponible en este momento. <br /> Volvé a intentar más tarde.',
          typeModal: 'error',
          isLogout: false,
        });
      }
    }
  };

  const postResendEmail = async () => {
    console.log('resend email desde modal confirmacion');
    isCheckOTP() ? setIsLoadingTokenOtp(true) : setIsLoadingResend(true);

    try {
      if (isCheckOTP()) {
        const dataToResend = {
          otp: valueRef.current.toString(),
        };
        await postResendProfile(dataToResend);
      } else {
        const dataToResend = {
          otp: null,
        };
        await postResendProfile(dataToResend);
      }
      fetchDataAndUpdateProfile();
      updateData();
      handleOnClose();
      setShowModalValidation(true);
      closeModalFromAlert();
      setShowModalToken(false);
      checkResendEmail();
      setResendEmail(false);
      setDrawerComponentEmail({ ...drawerComponentEmail, errorToken: false });
    } catch (err: any) {
      const dataAux: IProfileData = profileDataModified(data, getValues());
      setErrorForModalState(err, dataAux);
      setShowModalValidation(false);
      if (
        err.response.data.exception?.errorCode === '401' &&
        err.response.data.exception?.errorMessage === ERROR_MESSAGE_ADD_PERSON_EMAIL.OTP_INVALIDO
      ) {
        setDrawerComponentEmail({ ...drawerComponentEmail, errorToken: true });
        handleOnClose();
        setShowState(false);
        return;
      }
      if (
        err.response.data.exception?.errorCode === '401' &&
        err.response.data.exception?.errorMessage === ERROR_MESSAGE_ADD_PERSON_EMAIL.OTP_INVALIDO_ULTIMO_INTENTO
      ) {
        handleOnClose();
        setShowModalToken(false);
        setShowState(true);
        setDrawerComponentEmail({ ...drawerComponentEmail, errorToken: false });
      } else {
        handleOnClose();
        setShowModalToken(false);
        setShowState(true);
        setResendEmail(false);
        checkResendEmail();
        setResendEmail(false);
        setDrawerComponentEmail({ ...drawerComponentEmail, errorToken: false });
      }
    } finally {
      isCheckOTP() ? setIsLoadingTokenOtp(false) : setIsLoadingResend(false);
      onClose();
    }
  };

  const patchAndUpdate = async () => {
    const dataAux: IProfileData = profileDataModified(data, getValues());
    isCheckOTP() ? setIsLoadingTokenOtp(true) : setIsLoadingResend(true);
    try {
      if (isCheckOTP()) {
        const newDataToPatch = {
          emailAddress: dataAux.email.toString(),
          otp: valueRef.current.toString(),
        };
        await patchUpdateProfile(newDataToPatch);
      } else {
        const newDataToPatch = {
          emailAddress: dataAux.email.toString(),
        };
        await patchUpdateProfile(newDataToPatch);
      }
      fetchDataAndUpdateProfile();
      updateData();
      setShowModalValidation(true);
      closeModalFromAlert();
      setShowModalToken(false);
      checkResendEmail();
      setResendEmail(false);
    } catch (err: any) {
      setErrorForModalState(err, dataAux);
      if (
        err.response.data.exception?.errorCode === '401' &&
        err.response.data.exception?.errorMessage === ERROR_MESSAGE_ADD_PERSON_EMAIL.OTP_INVALIDO
      ) {
        setDrawerComponentEmail({ ...drawerComponentEmail, errorToken: true });
        handleOnClose();
        setShowState(false);
        return;
      }
      if (
        err.response.data.exception?.errorCode === '401' &&
        err.response.data.exception?.errorMessage === ERROR_MESSAGE_ADD_PERSON_EMAIL.OTP_INVALIDO_ULTIMO_INTENTO
      ) {
        handleOnClose();
        setShowModalToken(false);
        setShowState(true);
        setDrawerComponentEmail({ ...drawerComponentEmail, errorToken: false });
      } else {
        handleOnClose();
        setShowModalToken(false);
        setShowState(true);
        setResendEmail(false);
        checkResendEmail();
        setResendEmail(false);
        setDrawerComponentEmail({ ...drawerComponentEmail, errorToken: false });
      }
      handleOnClose();
      setShowModalToken(false);
      setShowState(true);
      setResendEmail(false);
      checkResendEmail();
      setResendEmail(false);
    } finally {
      isCheckOTP() ? setIsLoadingTokenOtp(false) : setIsLoadingResend(false);
      onClose();
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    setResendEmail(false);
    checkResendEmail();
    if (isCheckOTP()) {
      onClose();
      setShowModalToken(true);
    } else {
      patchAndUpdate();
    }
  };

  const handleOpenModalToken = () => {
    setShowState(false);
    setShowModalToken(true);
  };

  const handleCloseModalState = () => {
    setShowState(false);
    isOpenModalTokenFalse();
  };

  return (
    <>
      <Drawer isOpen={isOpen} onClose={handleOnClose}>
        <DrawerOverlay />
        <DrawerContent maxWidth='504px' className='container__drawer' id='drawer-content'>
          <DrawerCloseButton fontSize='none' marginTop='24px' marginRight='19px' id='drawer-close' />

          <DrawerHeader id='drawer-header'>
            <DrawerTitle id='drawer-title'>
              <Text textStyle='h4Bold'>{plainText.drawer.changeData.Email.title}</Text>
            </DrawerTitle>

            <DrawerDescription id='drawer-description'>{plainText.drawer.changeData.Email.description}</DrawerDescription>
          </DrawerHeader>
          <form onSubmit={handleSubmit} style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <DrawerBody id='drawer-body'>
              <Container>
                <EmailContainer>
                  <FormControl isInvalid={!!errors.email}>
                    <FormLabel marginBottom='0px' className={`${!!errors.email && 'label--error'}`} id='drawer-mail-label'>
                      {plainText.drawer.changeData.EmailPersonal.label}
                    </FormLabel>
                    <InputGroup>
                      <Input
                        type='text'
                        autoComplete='off'
                        id='drawer-input-email'
                        maxLength={80}
                        background='#fff'
                        border='1px solid rgb(116, 119, 127)'
                        {...register('email', {
                          required: `${plainText.drawer.changeData.EmailPersonal.errorRequired}`,
                          pattern: {
                            value: /^[a-zA-Z0-9._-]+@-?(?:[a-zA-Z0-9](-?[a-zA-Z0-9])*\.)+[a-zA-Z]{2,3}$/,
                            message: `${plainText.drawer.changeData.EmailPersonal.errorFormat}`,
                          },
                        })}
                        textColor={errors.email ? '#F03C3C' : '#74777F'}
                        fontSize='14px'
                        fontFamily='Inter'
                      />
                      {errors.email && <InputRightElement children={<img src={AlertIcon} alt='Alert Icon' />} />}
                    </InputGroup>
                    <FormErrorMessage marginTop='0px' id='drawer-email-textError'>
                      <Text textStyle='labelSm'>{errors?.email && errors.email?.message}</Text>
                    </FormErrorMessage>
                  </FormControl>
                </EmailContainer>
              </Container>
            </DrawerBody>

            <DrawerFooter borderTop='1px solid #0000003D' padding='20px 24px' id='drawer-footer'>
              <Button variant='outline' mr={3} onClick={handleOnClose} colorScheme='messenger' width='128px' id='drawer-button-cancel'>
                {plainText.drawer.changeData.button.Cancel}
              </Button>
              <Button
                type='submit'
                colorScheme='blue'
                width='128px'
                isDisabled={isDisabledButton()}
                onClick={handleSubmit}
                isLoading={isLoadingResend}
                loadingText={plainText.drawer.changeData.EmailPersonal.edicionEmailPersonal.buttonAction}
                id='drawer-button-accept'
              >
                {plainText.drawer.changeData.button.Accept}
              </Button>
            </DrawerFooter>
          </form>
        </DrawerContent>
      </Drawer>
      {isShowModalToken && (
        <ModalToken
          isLoading={isLoadingTokenOtp}
          title={plainText.modal.token.title}
          description={plainText.modal.token.subtitle}
          showModal={isShowModalToken}
          errorToken={drawerComponentEmail.errorToken}
          handleCloseModal={() => {
            setShowModalToken(false);
            isOpenModalTokenFalse();
            if (resendEmailButton) {
              checkResendEmail();
            }
          }}
          handleConfirm={() => returnValueToken}
          returnValueToken={returnValueToken}
        />
      )}
      <ModalState
        title={dataModalState.title}
        description={dataModalState.description}
        typeModal={dataModalState.typeModal}
        isLogout={dataModalState.isLogout}
        buttonTextPrimary='Entendido'
        isOpen={showState}
        handleClickButtonPrimary={dataModalState.typeModal === 'warning' ? handleOpenModalToken : handleCloseModalState}
      />
      <ModalValidation
        isLoading={isLoadingResend}
        mail={newData?.email}
        isOpen={showModalValidation}
        handleCloseModalValidation={() => {
          setShowModalValidation(false);
        }}
        handleCancelModalValidation={() => {
          setShowModalValidation(false);
          setResendEmail(false);
          checkResendEmail();
          handleReOpen();
        }}
        handleResendMail={() => {
          if (isCheckOTP()) {
            setShowModalValidation(false);
            setShowModalToken(true);
            setResendEmail(true);
          } else {
            postResendEmail();
          }
        }}
      />
    </>
  );
}

export default DrawerEmail;
