/* eslint-disable import/no-unresolved */

import { IErrorState } from '../../interfaces/IProfile';
import SectionText from '../../lang/es.json';
import Container from './styled';

function CompanyErrorState({ hasButton, buttonAction }: IErrorState) {
  return (
    <Container id='Error-company'>
      <h3 className='error__title' id='error-title'>
        {SectionText.section.ErrorState.title}
      </h3>
      <p className='error__description' id='error-description'>
        {SectionText.section.ErrorState.description}
      </p>
      {hasButton && (
        <button className='error__button' onClick={buttonAction}>
          {SectionText.section.ErrorState.buttonText}
        </button>
      )}
    </Container>
  );
}

export default CompanyErrorState;
