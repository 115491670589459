import styled from 'styled-components';

const Container = styled.div`
  width: 405px;
  height: 238px;
  padding: 16px;
  gap: 0px;
  border-radius: 12px;
  border: 1px solid #bec6dc;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  gap: 12px;
  display: flex;
  flex-direction: column;
  gap: initial;

  h1 {
    font-family: Red Hat Display;
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    text-align: left;
  }

  .section__title {
    display: flex;
    gap: 12px;
    justify-content: space-between;
  }

  .section__description {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
    text-align: left;
  }

  .section__description--secondary {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
    text-align: left;
    margin-top: 20px;
  }

  .section__button--help {
    min-width: unset;
    margin-right: 7px;
  }
`;

const BodyEmpty = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  .section__title {
    display: flex;
    justify-content: space-between;
  }
`;

const Body = styled.div`
  margin-top: 20px;
  height: 64%;

  h3 {
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0.10000000149011612px;
    text-align: left;
    margin-top: 28px;
  }

  .section__title {
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0.10000000149011612px;
    text-align: left;
    color: #5e5e61;
  }

  .section__value {
    font-family: Red Hat Display;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    text-align: left;
  }

  .section__description {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
    text-align: left;
  }

  .section__description--secondary {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
    text-align: left;
    margin-top: 20px;
  }

  .section__button .section__button-icon {
    position: absolute;
    margin-top: -8px;
  }
`;

const Footer = styled.div`
  display: flex;
  justify-content: end;

  .section__button {
    font-family: Inter;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.10000000149011612px;
    text-align: center;
    color: #2172dc;
    text-decoration: underline;
  }

  .section__button .section__button-icon {
    margin-left: 7px;
  }

  .section__button:hover,
  .section__button[data-hover] {
    color: rgb(74, 143, 251);
  }

  .section__button:active,
  .section__button[data-active] {
    color: rgb(0, 91, 191);
  }
`;
export { Body, BodyEmpty, Container, Footer, Header };
