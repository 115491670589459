/* eslint-disable react/jsx-no-duplicate-props */
import { Button, CloseButton, Text, useDisclosure } from '@chakra-ui/react';
import { Modal, ModalBody, ModalChakra, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@wow/modal';
import { PinInputComponent } from '@wow/pin-input';
import { useContext, useState } from 'react';

import { IModalToken } from '../../interfaces/IModalToken';
import { AppContext } from '../../providers/AppContextProvider';

function ModalToken({ title, description, showModal, handleCloseModal, returnValueToken, isLoading, errorToken }: IModalToken) {
  const { onClose } = useDisclosure();
  const { drawerAddEmail, setDrawerAddEmail, drawerComponentEmail, setDrawerComponentEmail } = useContext(AppContext) as any;
  const [isDisableConfirmarButton, setIsDisableConfirmarButton] = useState(true);
  const [valueInputToken, setValueInputToken] = useState('');
  const handleClose = () => {
    onClose();
    setIsDisableConfirmarButton(true);
    handleCloseModal();
    setDrawerAddEmail({ ...drawerAddEmail, isOpenModalToken: false, errorToken: false });
    setDrawerComponentEmail({ ...drawerComponentEmail, errorToken: false });
  };

  const handleChange = e => {
    if (e.length === 6) {
      setIsDisableConfirmarButton(false);
      setValueInputToken(e);
    } else {
      setIsDisableConfirmarButton(true);
      setDrawerAddEmail({ ...drawerAddEmail, errorToken: false });
      setDrawerComponentEmail({ ...drawerComponentEmail, errorToken: false });
    }
  };

  const handleConfirmButton = () => {
    returnValueToken(valueInputToken.toString());
    setIsDisableConfirmarButton(true);
  };

  return (
    <Modal data-testid='ModalToken' isOpen={showModal} onClose={() => handleClose()} closeOnOverlayClick={false} isCentered size='xl'>
      <ModalOverlay />
      <ModalContent data-testid='ModalToken-content'>
        <CloseButton data-testid='ModalToken-close-btn' onClick={() => handleClose()} sx={ModalChakra.close} />
        <ModalHeader>
          <Text textStyle='h3' data-testid='ModalToken-title'>
            {title}
          </Text>
        </ModalHeader>
        <ModalBody>
          <Text
            data-testid='ModalToken-body'
            textStyle='bodyMd'
            style={{ width: '528px' }}
            dangerouslySetInnerHTML={{ __html: description }}
          />
          <PinInputComponent
            nFields={6}
            type='number'
            variant='light'
            autoFocus
            onChange={e => handleChange(e)}
            supportText={errorToken && 'El token ingresado es incorrecto'}
            error={errorToken}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            data-testid='ModalToken-cancel'
            variant='primary-outline'
            onClick={() => handleClose()}
            style={{ width: '82px', height: '40px' }}
            id='button-cancel'
          >
            <Text textStyle='labelMdBold'>Cancelar</Text>
          </Button>
          <Button
            variant='primary'
            isLoading={isLoading}
            loadingText='Confirmando'
            onClick={() => handleConfirmButton()}
            isDisabled={isDisableConfirmarButton}
            size='md'
            id='button-confirm'
            data-testid='ModalToken-confirm'
          >
            <Text textStyle='labelMdBold'>Confirmar</Text>
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default ModalToken;
