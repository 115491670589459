import styled from 'styled-components';

const AppContainerPanel = styled.div`
  padding-left: 22px;
  padding-right: 30px;
  padding-top: 50px;
  height: 100%;
  width: 95%;
  margin-bottom: 150px;

  nav ol {
    margin-bottom: 12px;
    font-size: 0.8125rem;
    line-height: 1rem;
    letter-spacing: 0.03125rem;
  }

  nav ol > li {
    font-weight: 600 !important;
  }
`;
export default AppContainerPanel;
