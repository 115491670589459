/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-children-prop */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-prototype-builtins */
/* eslint-disable import/no-unresolved */
import { Input } from '@chakra-ui/input';
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  InputGroup,
  InputRightElement,
  Select,
  Text,
} from '@chakra-ui/react';
import { useToastOptions } from '@react-ib-mf/style-guide-ui';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import countriesMock from '../../__mocks__/countries.json';
import AlertIcon from '../../assets/icons/alert-circle.svg';
import { IPaisList } from '../../interfaces/ICountries';
import { IDrawerComponentProps, IProfileData } from '../../interfaces/IDrawer';
import { IUser } from '../../interfaces/IUser';
import plainText from '../../lang/es.json';
import { AppContext } from '../../providers/AppContextProvider';
import { patchUpdateOperatorProfile, patchUpdateProfile } from '../../services/api';
// import ModalState from '../../components/ModalState';
import { Container, DrawerDescription, DrawerTitle, EmailContainer, PhoneContainer } from './styled';

function DrawerOpComponent({ isOpen, onClose, data }: IDrawerComponentProps) {
  const countries: IPaisList[] = countriesMock.data.paisList.filter(pais => pais.paisCodTel);
  const [countryFlag, setCountryFlag] = useState<string>('ar');
  const [phoneMaxLength, setPhoneMaxLength] = useState(13);
  const [, setToastOptions] = useToastOptions();
  const [errorOperator, setErrorOperator] = useState<string>('');
  const { fetchDataAndUpdateProfile, setIsLoading } = useContext(AppContext) as any;
  const [isLoadingCompany, setIsLoadingCompany] = useState<boolean>(false);

  // const [isOpenModal, setIsOpenModal] = useState(false);
  // const [modalData, setModalData] = useState({ phone: '', mail: '', codCountry: '' });
  // const onCloseModal = () => setIsOpenModal(false);

  const {
    register,
    watch,
    reset,
    setValue,
    getValues,
    formState: { errors, isValid },
  } = useForm<IUser>({
    mode: 'onChange',
    reValidateMode: 'onBlur',
    defaultValues: {
      email: data?.email || '',
      countryCode: data?.countryCode || '',
      phoneNumber: data?.phoneNumber || '',
    },
  });
  const currentEmail = watch('email');
  const currentCodCountry = watch('countryCode');
  const currentPhone = watch('phoneNumber');

  useEffect(() => {
    reset({
      email: data?.email || '',
      countryCode: data?.countryCode || '',
      phoneNumber: data?.phoneNumber || '',
    });
  }, [data?.email, data?.phoneNumber, data?.countryCode, reset]);

  useEffect(() => {
    setPhoneMaxLength(14 - data?.countryCode?.length);
  }, [data?.countryCode]);

  const isDisabledButton = (): boolean =>
    !isValid ||
    (currentEmail === data.email && currentPhone === data.phoneNumber && currentCodCountry === data.countryCode) ||
    errorOperator !== '';
  // eslint-disable-next-line no-useless-escape
  const getCountryByVal = (value: string): IPaisList => countries.find(country => country.codDN === value || country.paisCodTel === value);

  const handleOnClose = () => {
    reset({
      email: data?.email || '',
      countryCode: data?.countryCode || '',
      phoneNumber: data?.phoneNumber || '',
    });
    setErrorOperator('');
    onClose();
  };

  const handlePhoneKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      !(
        (event.keyCode >= 48 && event.keyCode <= 57) ||
        (event.keyCode >= 96 && event.keyCode <= 105) ||
        event.keyCode === 8 ||
        event.keyCode === 9 ||
        event.keyCode === 37 ||
        event.keyCode === 39 ||
        event.keyCode === 46
      )
    ) {
      event.preventDefault();
    }
  };

  useEffect(() => {
    if (
      getCountryByVal(getValues().countryCode)?.codDN?.toLowerCase() !== undefined &&
      getCountryByVal(getValues().countryCode)?.codDN?.toLowerCase() !== null
    ) {
      setCountryFlag(getCountryByVal(getValues().countryCode)?.codDN?.toLowerCase());
    } else {
      setCountryFlag('ar');
    }
  }, [countryFlag, getCountryByVal, getValues]);

  const profileDataModified = (sourceData, modifiedData) => {
    const newData: IProfileData = { countryCode: '' };

    Object.keys(sourceData).forEach(key => {
      if (sourceData[key] !== modifiedData[key]) {
        newData[key] = modifiedData[key];
      }
    });

    if (modifiedData.countryCode === sourceData.countryCode) {
      newData.countryCode = sourceData.countryCode.toString();
    } else {
      newData.countryCode = modifiedData.countryCode.toString();
      if (!newData.hasOwnProperty('phoneNumber')) {
        newData.phoneNumber = modifiedData.phoneNumber;
      }
    }

    if (newData.phoneNumber === '') {
      newData.phoneNumber = null;
    }

    return newData;
  };

  const handleChangeSelect = (e, field) => {
    const valueField = e.target.value;
    if (field === 'countryCode') {
      setCountryFlag(getCountryByVal(valueField)?.codDN?.toLowerCase());
    }

    const digits = valueField.replace('+', '').length; // Contar dígitos del código sin el signo +
    setPhoneMaxLength(14 - digits);

    setValue('phoneNumber', null, { shouldValidate: true });
  };

  const handleSubmit = async e => {
    e.preventDefault();

    const newData = profileDataModified(data, getValues());

    try {
      setIsLoadingCompany(true);
      await patchUpdateOperatorProfile(newData);
      onClose();
      setToastOptions({
        status: 'success',
        description: plainText.toast.success,
        autoClose: true,
      });
      setIsLoading(true);
      fetchDataAndUpdateProfile();
      setErrorOperator('');
      setIsLoadingCompany(false);
    } catch (error: any) {
      setIsLoadingCompany(false);
      if (error.response.data.exception.errorCode === '409' && error.response.data.exception.errorKey.toString() === '3024') {
        setErrorOperator('El email ya está registrado en la empresa');
        return;
      }

      if (error.response.data.exception.errorCode === '409' && error.response.data.exception.errorKey.toString() === '3023') {
        setErrorOperator('El email ya está registrado');
        return;
      }

      handleOnClose();
      setToastOptions({
        status: 'error',
        description: plainText.toast.error,
        autoClose: true,
      });
    }
  };

  return (
    <>
      <Drawer isOpen={isOpen} onClose={handleOnClose}>
        <DrawerOverlay />
        <DrawerContent maxWidth='504px' className='container__drawer' id='drawer-content'>
          <DrawerCloseButton fontSize='none' marginTop='24px' marginRight='19px' id='drawer-close' />

          <DrawerHeader id='drawer-header'>
            <DrawerTitle id='drawer-title'>
              <Text textStyle='h4Bold'>{plainText.drawer.changeData.title}</Text>
            </DrawerTitle>

            <DrawerDescription id='drawer-description'>{plainText.drawer.changeData.description}</DrawerDescription>
          </DrawerHeader>
          <form onSubmit={handleSubmit} style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <DrawerBody id='drawer-body'>
              <Container>
                <PhoneContainer>
                  <FormControl isInvalid={!!errors.countryCode || !!errors.phoneNumber}>
                    <FormLabel marginBottom='0px' id='drawer-phone-label' className={`${!!errors.phoneNumber && 'label--error'}`}>
                      {plainText.drawer.changeData.Phone.label}
                    </FormLabel>
                    <div className='inputs-phone'>
                      <InputGroup width='fit-content'>
                        <Box
                          data-testid='areaCodeFlag'
                          position='absolute'
                          left={2}
                          width={6}
                          height='100%'
                          display='inline-flex'
                          justifyContent='center'
                          alignItems='center'
                          pointerEvents='none'
                          zIndex={1}
                          top='50%'
                          transform='translateY(-50%)'
                        >
                          <span className={`flag flag-${countryFlag}`} />
                        </Box>
                        <Select
                          disabled
                          id='drawer-input-code-country'
                          width='116px'
                          fontSize='14px'
                          fontFamily='Inter'
                          background='#fff'
                          border='1px solid rgb(116, 119, 127)'
                          textColor={errors.phoneNumber ? '#F03C3C' : '#74777F'}
                          value={watch('countryCode')}
                          {...register('countryCode', {
                            required: plainText.drawer.changeData.Phone.errorRequired,
                            onChange: e => {
                              handleChangeSelect(e, 'countryCode');
                              setValue('countryCode', e.target.value);
                            },
                          })}
                        >
                          {countries.map(({ codDN, paisCodTel }: IPaisList) => (
                            <option value={paisCodTel} key={codDN} selected={data?.countryCode === paisCodTel}>
                              +{data?.countryCode}
                            </option>
                          ))}
                        </Select>
                      </InputGroup>
                      <InputGroup>
                        <Input
                          autoComplete='off'
                          id='drawer-input-phone'
                          background='#fff'
                          textColor={errors.phoneNumber ? '#F03C3C' : '#74777F'}
                          fontSize='14px'
                          fontFamily='Inter'
                          border='1px solid rgb(116, 119, 127)'
                          type='tel'
                          maxLength={phoneMaxLength}
                          minLength={0}
                          onKeyDown={handlePhoneKeyDown}
                          {...register('phoneNumber', {
                            ...(data?.phoneNumber !== '' && { required: plainText.drawer.changeData.Phone.errorRequired }),
                            pattern: {
                              value: /^[0-9]+$/,
                              message: plainText.drawer.changeData.Phone.errorFormat,
                            },
                            maxLength: { value: phoneMaxLength, message: plainText.drawer.changeData.Phone.errorFormat },
                          })}
                        />
                        {errors.phoneNumber && <InputRightElement children={<img src={AlertIcon} alt='Alert Icon' />} />}
                      </InputGroup>
                    </div>

                    {!errors.phoneNumber && (
                      <FormHelperText marginTop='0px' id='drawer-phone-textHelper'>
                        <Text textStyle='labelSm'>{plainText.drawer.changeData.Phone.description}</Text>
                      </FormHelperText>
                    )}
                    <FormErrorMessage marginTop='0px' id='drawer-phone-textError'>
                      {errors.phoneNumber && errors.phoneNumber.message}
                    </FormErrorMessage>
                  </FormControl>
                </PhoneContainer>

                <EmailContainer>
                  <FormControl isInvalid={!!errors.email || !!errorOperator}>
                    <FormLabel
                      marginBottom='2px'
                      className={`${!!errors.email && 'label--error'} ${errorOperator && 'label--error'}`}
                      id='drawer-mail-label'
                    >
                      {plainText.drawer.changeData.Email.label}
                    </FormLabel>
                    <InputGroup>
                      <Input
                        type='text'
                        autoComplete='off'
                        id='drawer-input-email'
                        maxLength={80}
                        background='#fff'
                        border='1px solid rgb(116, 119, 127)'
                        {...register('email', {
                          required: `${plainText.drawer.changeData.Email.errorRequired}`,
                          pattern: {
                            value: /^[a-zA-Z0-9._-]+@-?(?:[a-zA-Z0-9](-?[a-zA-Z0-9])*\.)+[a-zA-Z]{2,3}$/,
                            message: `${plainText.drawer.changeData.Email.errorFormat}`,
                          },
                          onChange: (e: any) => setErrorOperator(''),
                        })}
                        textColor={errors.email || errorOperator ? '#F03C3C' : '#74777F'}
                        fontSize='14px'
                        fontFamily='Inter'
                      />
                      {errors.email && <InputRightElement children={<img src={AlertIcon} alt='Alert Icon' />} />}
                      {errorOperator && <InputRightElement children={<img src={AlertIcon} alt='Alert Icon' />} />}
                    </InputGroup>

                    <FormErrorMessage marginTop='2px' id='drawer-email-textError'>
                      <Text textStyle='labelSm'>
                        {errors?.email && errors.email?.message} {errorOperator && <p>{errorOperator}</p>}
                      </Text>
                    </FormErrorMessage>
                  </FormControl>
                </EmailContainer>
              </Container>
            </DrawerBody>

            <DrawerFooter borderTop='1px solid #0000003D' padding='20px 24px' id='drawer-footer'>
              <Button variant='outline' mr={3} onClick={handleOnClose} colorScheme='messenger' width='128px' id='drawer-button-cancel'>
                {plainText.drawer.changeData.button.Cancel}
              </Button>
              <Button
                type='submit'
                colorScheme='blue'
                width='128px'
                isDisabled={isDisabledButton()}
                onClick={null}
                id='drawer-button-accept'
                isLoading={isLoadingCompany}
              >
                {plainText.drawer.changeData.button.Accept}
              </Button>
            </DrawerFooter>
          </form>
        </DrawerContent>
      </Drawer>

      {/* <ModalState
        title={plainText.modal.states.info.title}
        typeModal={plainText.modal.states.info.type}
        description={plainText.modal.states.info.description}
        onClose={onCloseModal}
        isOpen={isOpenModal}
        data={modalData}
        buttonTextPrimary={plainText.modal.buttonPrimary}
        buttonTextSecondary={plainText.modal.buttonSecondary}
        handleClickButtonPrimary={null}
        handleClickButtonSecondary={null}
      /> */}
    </>
  );
}

export default DrawerOpComponent;
