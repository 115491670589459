/* eslint-disable import/no-unresolved */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-restricted-globals */
// eslint-disable-next-line import/no-unresolved

import { Button, CloseButton, Spinner, Text } from '@chakra-ui/react';
import { useAuthorizer } from '@react-ib-mf/header-menu-ui';
import { Modal, ModalBody, ModalChakra, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@wow/modal';
import { useEffect, useState } from 'react';

import { IModalStateProps } from '../../interfaces/IModalState';

function ModalState({
  title,
  typeModal,
  description,
  isOpen,
  onClose,
  data,
  handleClickButtonPrimary,
  handleClickButtonSecondary,
  buttonTextPrimary,
  buttonTextSecondary,
  isLogout,
}: IModalStateProps) {
  const [timer, setTimer] = useState(300);
  const [{ logout }] = useAuthorizer();

  const formatTime = (time: number) => {
    const seconds = String(time % 60);
    return `${seconds}`;
  };

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;

    if (isOpen && isLogout) {
      window.sessionStorage.setItem('PERSONA_BLOQUEADA', 'true');
      if (timer > 0) {
        interval = setInterval(() => {
          setTimer(prev => prev - 1);
        }, 1000);
      } else {
        logout();
      }
    }

    return () => {
      if (interval) clearInterval(interval);
      if (!isOpen) {
        setTimer(300);
      }
    };
  }, [timer, isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} variant='states' closeOnOverlayClick={false} isCentered size='xl'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader state={typeModal} style={{ textAlign: 'center', paddingTop: '30px' }}>
          <Text dangerouslySetInnerHTML={{ __html: title }} />
        </ModalHeader>
        <ModalBody>
          <Text dangerouslySetInnerHTML={{ __html: description }} />
        </ModalBody>
        {isLogout ? (
          <Text style={{ display: 'flex' }} textStyle='bodyMdSemi'>
            <Spinner
              thickness='2px'
              speed='0.45s'
              emptyColor='gray.200'
              color='rgb(74, 143, 251)'
              size='sm'
              marginRight='5px'
              marginTop='1px'
            />
            <strong>Cerraremos tu sesión en {formatTime(timer)} segundos</strong>
          </Text>
        ) : (
          <ModalFooter>
            {handleClickButtonSecondary && (
              <Button variant='primary-outline' onClick={handleClickButtonSecondary}>
                {buttonTextSecondary}
              </Button>
            )}
            <Button variant='primary' onClick={handleClickButtonPrimary}>
              {buttonTextPrimary}
            </Button>
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
}

export default ModalState;
