/* eslint-disable import/prefer-default-export */
import { AlertComponent, AlertDescription, AlertTitle } from '@wow/alert';
import { useEffect, useState } from 'react';

import { IALertBanner } from '../../interfaces/IProfile';

function AlertBanner({ buttonLabel, title, description, openModal, showAlert }: IALertBanner) {
  const [alerta, setAlerta] = useState(true);

  useEffect(() => {
    setAlerta(showAlert);
  }, [showAlert]);

  return (
    alerta && (
      <AlertComponent
        marginBottom='20px'
        id='alert-id'
        variant='warning'
        onClose={() => setAlerta(false)} // Función para cambiar el estado
        buttonLabel={buttonLabel}
        onClick={() => openModal()}
      >
        <AlertTitle>{title}</AlertTitle>
        <AlertDescription>{description}</AlertDescription>
      </AlertComponent>
    )
  );
}

export default AlertBanner;
