import { Button, CloseButton, Text, useDisclosure } from '@chakra-ui/react';
import {
  Modal,
  ModalBody,
  ModalBox,
  ModalChakra,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalLeftBox,
  ModalOverlay,
  ModalRightBox,
} from '@wow/modal';
import { useWizard, WizardComponent } from '@wow/wizard';
import { useEffect } from 'react';

import welcomeImage from '../../assets/icons/welcome-modal.svg';
import wizardSteps from '../Wizard/wizardSteps';

function WelcomeWizardModal() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [initialized, setInitialized, currentStep, setCurrentStep] = useWizard();

  const handleFinishWizard = () => {
    localStorage.setItem('wizard-profile', 'true');
  };

  useEffect(() => {
    if (localStorage.getItem('wizard-profile') == null) {
      onOpen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = () => {
    onClose();
    handleFinishWizard();
  };

  const handleExplore = () => {
    onClose();
    setInitialized(true);
  };

  return (
    <>
      {/* <Button onClick={onOpen}>Abrir Modal de Instructivo</Button> */}
      <Modal isOpen={isOpen} onClose={handleClose} variant='welcome' isCentered autoFocus={false} lockFocusAcrossFrames={false}>
        <ModalOverlay />
        <ModalContent>
          <CloseButton onClick={handleClose} sx={ModalChakra.close} />
          <ModalBox>
            <ModalLeftBox>
              <ModalHeader>
                <Text textStyle='h3'>¡Conocé tu nuevo perfil!</Text>
              </ModalHeader>
              <ModalBody>
                <Text>
                  Explorá una experiencia más personalizada: visualizá y editá fácilmente tus datos, cambiá tus credenciales, adherite a la
                  App, ¡todo a un clic!
                </Text>
              </ModalBody>
              <ModalFooter>
                <Button variant='primary-outline' onClick={handleClose}>
                  En otro momento
                </Button>
                <Button variant='primary' onClick={() => handleExplore()}>
                  Explorar
                </Button>
              </ModalFooter>
            </ModalLeftBox>
            <ModalRightBox>
              <img src={welcomeImage} alt='img' />
            </ModalRightBox>
          </ModalBox>
        </ModalContent>
      </Modal>
      <WizardComponent
        id='wizard-profile'
        steps={wizardSteps}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        initialized={initialized}
        setInitialized={setInitialized}
        width={305}
        height={220}
        offset={10}
        finishLabel='Finalizar'
        onFinish={handleFinishWizard}
        hasWelcome={false}
      />
    </>
  );
}

export default WelcomeWizardModal;
