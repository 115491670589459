/* eslint-disable consistent-return */
/* eslint-disable dot-notation */
/* eslint-disable simple-import-sort/imports */
// eslint-disable-next-line import/no-unresolved
import { getToken, isCheckOTP } from '@react-ib-mf/style-guide-ui';
import axios from 'axios';
import { IProfileData } from '../interfaces/IDrawer';
import getTokenForSessionStorage from '../utils/sesionStorage';

/*
// No se debe setear el axios.defaults.URLBase ya que pisa la url de mf-home-interna
*/

interface IResendEmail {
  otp?: string | undefined;
}

const axiosInstance = axios.create({
  headers: {
    Authorization: `Bearer ${getToken()}`,
    'x-jwt-assertion': getTokenForSessionStorage(),
    'Content-Type': 'application/json',
  },
});

const patchUpdateProfile = data => {
  axiosInstance.defaults.headers['user_key'] = process.env.API_USER_KEY;
  if (isCheckOTP()) {
    return axiosInstance.patch(`${process.env.PERFIL_BFF_URL}/profile/v1/management/persons/emails?hasOtp=true`, data);
  }
  return axiosInstance.patch(`${process.env.PERFIL_BFF_URL}/profile/v1/management/persons/emails?hasOtp=false`, data);
};

const getProfile = () => {
  axiosInstance.defaults.headers['user_key'] = process.env.API_USER_KEY;
  return axiosInstance.get(`${process.env.PERFIL_BFF_URL}/profile/v1/management/operatorProfiles`);
};

const patchUpdateOperatorProfile = (data: IProfileData) => {
  axiosInstance.defaults.headers['user_key'] = process.env.API_USER_KEY;
  return axiosInstance.patch(`${process.env.PERFIL_BFF_URL}/profile/v1/management/operatorProfiles`, data);
};

const postResendProfile = (data: IResendEmail) => {
  axiosInstance.defaults.headers['user_key'] = process.env.API_USER_KEY;
  if (isCheckOTP()) {
    return axiosInstance.post(`${process.env.PERFIL_BFF_URL}/profile/v1/persons/emails/resend?hasOtp=true`, data);
  }
  if (!isCheckOTP()) {
    return axiosInstance.post(`${process.env.PERFIL_BFF_URL}/profile/v1/persons/emails/resend?hasOtp=false`, data);
  }
};

interface IPostAddEmailPerson {
  email: string;
  hasOtp: boolean;
  token: string;
}

const postAddEmailPerson = ({ email, hasOtp, token }: IPostAddEmailPerson) => {
  axiosInstance.defaults.headers['user_key'] = process.env.API_USER_KEY;

  return axiosInstance.post(`${process.env.PERFIL_BFF_URL}/profile/v1/management/persons/emails?hasOtp=${hasOtp}`, {
    emailAddress: email,
    otp: token,
  });
};

const getPerfilMenu = () => axios.get('home/cliente/perfil/menu');

// eslint-disable-next-line import/export
export { getPerfilMenu, getProfile, patchUpdateOperatorProfile, patchUpdateProfile, postAddEmailPerson, postResendProfile };
