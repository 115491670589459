/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-children-prop */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-prototype-builtins */
/* eslint-disable import/no-unresolved */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Input, InputRightElement } from '@chakra-ui/input';
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormErrorMessage,
  FormLabel,
  InputGroup,
  Text,
} from '@chakra-ui/react';
import { isCheckOTP } from '@react-ib-mf/style-guide-ui';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import AlertIcon from '../../assets/icons/alert-circle.svg';
import { IDrawerAddEmailProps } from '../../interfaces/IDrawerAddEmail';
import { IUser } from '../../interfaces/IUser';
import plainText from '../../lang/es.json';
import { Container, DrawerDescription, DrawerTitle, EmailContainer } from './styled';

function DrawerAddEmail({ isOpen, closeDrawer, submit, isLoadingBtn, errorDrawer }: IDrawerAddEmailProps) {
  const {
    register,
    watch,
    reset,
    getValues,
    formState: { errors, isValid },
  } = useForm<IUser>({
    mode: 'onChange',
    reValidateMode: 'onBlur',
    defaultValues: {
      email: '',
    },
  });

  const currentEmail = watch('email');

  const handleCloseDrawer = () => {
    reset({
      email: '',
    });
    closeDrawer();
  };

  const handleSubmitDrawer = (e: any) => {
    e.preventDefault();
    submit(currentEmail);
  };

  useEffect(() => {
    if (errorDrawer) {
      reset({
        email: '',
      });
    }
  }, [errorDrawer]);

  return (
    <Drawer isOpen={isOpen} onClose={handleCloseDrawer}>
      <DrawerOverlay />
      <DrawerContent maxWidth='504px' className='container__drawer' id='drawer-content'>
        <DrawerCloseButton fontSize='none' marginTop='24px' marginRight='19px' id='drawer-close' />

        <DrawerHeader id='drawer-header'>
          <DrawerTitle id='drawer-title'>
            <Text textStyle='h4Bold'>{plainText.drawer.changeData.EmailPersonal.title}</Text>
          </DrawerTitle>

          <DrawerDescription id='drawer-description'>{plainText.drawer.changeData.EmailPersonal.description}</DrawerDescription>
        </DrawerHeader>
        <form onSubmit={e => handleSubmitDrawer(e)} style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
          <DrawerBody id='drawer-body'>
            <Container>
              <EmailContainer>
                <FormControl isInvalid={!!errors.email}>
                  <FormLabel marginBottom='0px' className={`${!!errors.email && 'label--error'}`} id='drawer-mail-label'>
                    {plainText.drawer.changeData.EmailPersonal.label}
                  </FormLabel>
                  <InputGroup>
                    <Input
                      {...register('email', {
                        required: `${plainText.drawer.changeData.EmailPersonal.errorRequired}`,
                        pattern: {
                          value: /^[a-zA-Z0-9._-]+@-?(?:[a-zA-Z0-9](-?[a-zA-Z0-9])*\.)+[a-zA-Z]{2,3}$/,
                          message: `${plainText.drawer.changeData.EmailPersonal.errorFormat}`,
                        },
                      })}
                      type='text'
                      autoComplete='off'
                      id='drawer-input-email'
                      maxLength={80}
                      background='#fff'
                      border='1px solid rgb(116, 119, 127)'
                      textColor={errors.email ? '#F03C3C' : '#74777F'}
                      fontSize='14px'
                      fontFamily='Inter'
                    />
                    {errors.email && <InputRightElement children={<img src={AlertIcon} alt='Alert Icon' />} />}
                  </InputGroup>
                  <FormErrorMessage marginTop='0px' id='drawer-email-textError'>
                    <Text textStyle='labelSm'>{errors?.email && errors.email?.message}</Text>
                  </FormErrorMessage>
                </FormControl>
              </EmailContainer>
            </Container>
          </DrawerBody>

          <DrawerFooter borderTop='1px solid #0000003D' padding='20px 24px' id='drawer-footer'>
            <Button variant='outline' mr={3} onClick={handleCloseDrawer} colorScheme='messenger' width='128px' id='drawer-button-cancel'>
              {plainText.drawer.changeData.button.Cancel}
            </Button>
            <Button
              type='submit'
              isLoading={isLoadingBtn}
              loadingText='Confirmando'
              colorScheme='blue'
              width='128px'
              isDisabled={!(currentEmail.length >= 1) || !!errors.email}
              onClick={e => handleSubmitDrawer(e)}
              id='drawer-button-accept'
            >
              {plainText.drawer.changeData.button.Accept}
            </Button>
          </DrawerFooter>
        </form>
      </DrawerContent>
    </Drawer>
  );
}

export default DrawerAddEmail;
