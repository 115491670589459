/* eslint-disable import/no-unresolved */
/* eslint-disable no-nested-ternary */
import { Button, Spinner, Text, Tooltip } from '@chakra-ui/react';
import { OpenLink } from '@react-ib-mf/style-guide-ui';
import { useContext, useEffect, useState } from 'react';

import iconHelp from '../../../assets/icons/help-circle.svg';
import iconUpdate from '../../../assets/icons/update.svg';
import { ISimpleSection } from '../../../interfaces/IProfile';
import SectionText from '../../../lang/es.json';
import { AppContext } from '../../../providers/AppContextProvider';
import { Body, BodyEmpty, Container, Footer, Header } from './styled';

function SimpleSection({
  title,
  sectionTitle,
  description,
  descriptionSecond,
  hasDate,
  buttonText,
  id,
  url,
  openMode,
  hasIcon,
}: ISimpleSection) {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const { profileData, profileStatus, fetchDataAndUpdateProfile, isLoading, setIsLoading } = useContext(AppContext) as any;

  useEffect(() => {
    fetchDataAndUpdateProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUpdateProfile = () => {
    setIsLoading(true);
    fetchDataAndUpdateProfile();
  };

  return (
    <Container id={id}>
      <Header>
        <div className='section__title'>
          <h1>
            <Text textStyle='titleLg' style={{ fontWeight: 'bold' }}>
              {title}
            </Text>
          </h1>
          {hasIcon && (
            <Tooltip
              isDisabled={!isTooltipOpen}
              background='#2F3034'
              hasArrow
              label={SectionText.section.token.header.tooltip}
              placement='right'
              fontSize='12px'
              fontFamily='Inter'
              fontWeight={400}
              maxWidth='294px'
              padding='8px 20px'
              borderRadius={10}
              textAlign='center'
              isOpen={isTooltipOpen}
              onClose={() => setIsTooltipOpen(false)}
              onOpen={() => setIsTooltipOpen(true)}
            >
              <Button
                variant='link'
                className='section__button--help'
                onMouseEnter={() => setIsTooltipOpen(true)}
                onMouseLeave={() => setIsTooltipOpen(false)}
              >
                <img src={iconHelp} alt='help Icon' />
              </Button>
            </Tooltip>
          )}
        </div>
      </Header>
      {hasDate && (
        <>
          {isLoading && (
            <BodyEmpty>
              <div className='section__spinner'>
                <Spinner thickness='4px' speed='0.45s' emptyColor='gray.200' color='rgb(74, 143, 251)' size='xl' />
              </div>
            </BodyEmpty>
          )}
          {!isLoading &&
            !profileData &&
            (profileStatus === 400 || profileStatus === 401 || profileStatus === 409 || profileStatus === 404) && (
              <>
                <Body>
                  <div className='section__description'>{description}</div>

                  <div className='section__title'>
                    <h3>{`${sectionTitle} ${SectionText.section.password.errorState}`} </h3>
                  </div>
                </Body>
                <Footer>
                  <OpenLink url={url} openMode={openMode} reuseTab>
                    <Button variant='link' className='section__button'>
                      {buttonText}
                    </Button>
                  </OpenLink>
                </Footer>
              </>
            )}
          {!isLoading && !profileData && (profileStatus === 500 || profileStatus === 0) && (
            <>
              <Body>
                <div className='section__description'>{description}</div>

                <div className='section__title'>
                  <h3>
                    {`${sectionTitle} ${SectionText.section.password.errorState}`}
                    <Button variant='link' className='section__button' onClick={handleUpdateProfile}>
                      <img src={iconUpdate} alt='Logout Icon' className='section__button-icon' />
                    </Button>
                  </h3>
                </div>
              </Body>
              <Footer>
                <OpenLink url={url} openMode={openMode} reuseTab>
                  <Button variant='link' className='section__button'>
                    {buttonText}
                  </Button>
                </OpenLink>
              </Footer>
            </>
          )}
          {!isLoading && profileData && (
            <>
              <Body>
                <div className='section__description'>{description}</div>

                <div className='section__title'>
                  <h3>
                    <Text textStyle='labelSmBold'>{`${sectionTitle} ${
                      hasDate
                        ? profileData?.passwordChangeDate !== null &&
                          profileData?.passwordChangeDate !== undefined &&
                          profileData?.passwordChangeDate !== ''
                          ? profileData?.passwordChangeDate
                          : SectionText.section.password.errorState
                        : ''
                    }`}</Text>
                  </h3>
                </div>
              </Body>
              <Footer>
                <OpenLink url={url} openMode={openMode} reuseTab>
                  <Button variant='link' className='section__button'>
                    <Text textStyle='labelSmBold'>{buttonText}</Text>
                  </Button>
                </OpenLink>
              </Footer>
            </>
          )}
        </>
      )}

      {!hasDate && (
        <>
          <Body>
            <div className='section__description'>{description}</div>
            {descriptionSecond && <div className='section__description--secondary'>{descriptionSecond}</div>}
          </Body>
          <Footer>
            <OpenLink url={url} openMode={openMode} reuseTab>
              <Button variant='link' className='section__button'>
                <Text textStyle='labelSmBold'>{buttonText}</Text>
              </Button>
            </OpenLink>
          </Footer>
        </>
      )}
    </Container>
  );
}

export default SimpleSection;
