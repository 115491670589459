export const TEXT_PRESENTATION = {
  title: '¡Conocé tu nuevo perfil!',
  description: 'Personalizá tu experiencia y disfrutá de las novedades desde acá.',
};

export const TEXT_STEP_ONE = {
  title: 'Tus datos personales, a un clic',
  description: 'Accedé a un resumen de tu información personal y editala con el lápiz, ¡para mantenerla siempre actualizada!',
};
export const TEXT_STEP_TWO = {
  title: 'Tus datos en la empresa',
  description: 'Visualizá y gestioná la información relacionada a tu nombre, rol, teléfono e email. Podés editarlos si es necesario.',
};

export const TEXT_STEP_THREE = {
  title: 'Usuario y contraseña',
  description: 'Cambiá tus credenciales, como usuario y contraseña. ¡Todo desde un solo lugar para agilizar tu operatoria!',
};

export const TEXT_STEP_FOUR = {
  title: 'Adhesión a la App',
  description: 'Vinculá tu cuenta y obtené tu Token de forma más ágil para operar con mayor rapidez y seguridad.',
};
